import { CreatedIn } from '@typing/OrderData';

export type ITraveler = {
  id: string;
  lastName: string;
  firstName: string;
  midName: string;
};

export type ITravelerPA = {
  firstName: string;
  lastName: string;
  midName?: string;
  qrCodeId?: string;
};

export type Traveler = {
  lastName: string;
  firstName: string;
  midName?: string;
  country?: string;
  docType?: string;
  city?: string | null;
  phoneNumber?: string;
  email?: string;
  pCategory?: string;
};

export type Tourist = Traveler & {
  isPrivilegeModalVisible: boolean;
  id: string;
  number1: string;
  number2: string;
  noMidName: boolean;
  cityUUID: string;
  countryUUID?: string;
  mainMember: boolean;
  isEmployee: boolean;
  createdIn?: CreatedIn;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DocumentType = {
  number2?: string;
  number1?: string;
};

export type PrivilegeCategory = {
  name?: string;
  percent?: number;
};

export type Privilege = {
  id: string;
  name: string;
  objType: 'pCategory';
};

export type Country = {
  id: string;
  name: string;
  objType: 'contry';
};

export type ExcelData = {
  [Key in keyof typeof ExcelDataKeys]: string;
};

export enum ExcelDataKeys {
  lastName = 'Фамилия',
  firstName = 'Имя',
  midName = 'Отчество',
  country = 'Гражданство',
  city = 'Прописка',
  phoneNumber = 'Телефон',
  email = 'Электронная почта',
  privileges = 'Льготы',
}

export default {
  CAR_BRANDS: [
    'Acura ',
    'Alfa Romeo',
    'Alpina',
    'Asia',
    'Aston Martin',
    'Audi',
    'BAIC',
    'BMW',
    'BYD',
    'Bentley',
    'Brilliance',
    'Bugatti',
    'Buick',
    'Cadillac',
    'Changan',
    'Chery',
    'Chevrolet',
    'Chrysler',
    'Citroen',
    'DW Hower',
    'Dacia',
    'Daewoo',
    'Daihatsu',
    'Daimler',
    'Datsun',
    'DeLorean',
    'Derways',
    'Dodge',
    'Dongfeng',
    'Eagle',
    'FAW',
    'Ferrari',
    'Fiat',
    'Fisker',
    'Ford',
    'Foton',
    'GAC',
    'GMC',
    'Geely',
    'Genesis',
    'Geo',
    'Great Wall',
    'Hafei',
    'Haima',
    'Haval',
    'Hawtai',
    'Honda',
    'Hummer',
    'Hyundai',
    'Infiniti',
    'Iran Khodro',
    'Isuzu',
    'JAC',
    'Jaguar',
    'Jeep',
    'Kia',
    'Koenigsegg',
    'Lamborghini',
    'Lancia',
    'Land Rover',
    'Lexus',
    'Lifan',
    'Lincoln',
    'Lotus',
    'Luxgen',
    'MG',
    'Marussia',
    'Maserati',
    'Maybach',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mercury',
    'Mini',
    'Mitsubishi',
    'Mitsuoka',
    'Nissan',
    'Oldsmobile',
    'Opel',
    'Pagani',
    'Peugeot',
    'Plymouth',
    'Pontiac',
    'Porsche',
    'Proton',
    'Ravon',
    'Renault',
    'Renault Samsung',
    'Roewe',
    'Rolls-Royce',
    'Rover',
    'SEAT',
    'Saab',
    'Saturn',
    'Scion',
    'Skoda',
    'Smart',
    'SsangYong',
    'Subaru',
    'Suzuki',
    'Tesla',
    'Tianye',
    'Toyota',
    'Volkswagen',
    'Volvo',
    'Vortex',
    'Wiesmann',
    'Xin Kai',
    'Xpeng',
    'ZX',
    'Zotye',
    'Аурус',
    'Богдан',
    'ГАЗ',
    'ЗАЗ',
    'ИЖ',
    'Лада',
    'ЛуАЗ',
    'Москвич',
    'Прочие авто',
    'ТагАЗ',
    'УАЗ',
  ],
  TRANSPORT_PRIVATE_ID: '7696c057-9fc3-280e-9662-f1f7ca5ebbe2',
  TRANSPORT_PUBLIC_ID: 'c435338a-679d-b9ff-3689-3b44f9bd7788',
};

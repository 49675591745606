import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { Route, HashRouter, Redirect } from 'react-router-dom';

import { appStore } from '@store';
import { Provider } from 'mobx-react';
import { ROUTES } from '@constants';
import { LocalStorageUtils } from '@utils';
import { version } from '@configuration/config';
import { initializeApp } from '@cuba-platform/rest';
import { SERVER_HOST } from '@configuration/config';
import { CubaAppProvider } from '@cuba-platform/react';
import { antdLocaleMapping, messagesMapping } from '@configuration/i18n/i18nMappings';

import App from '@components/App';
import { ErrorBoundary } from '@components/common';
import CompanyAccount from '@pages/CompanyAccount/CompanyAccount';

import 'moment/locale/ru';

// import all static app styles
import './style.scss';

export const cubaREST = initializeApp({
  name: 'ausweiss',
  apiUrl: SERVER_HOST || '/components/rest/',
  storage: window.localStorage,
  defaultLocale: 'ru',
});

const { ROUTE_COMPANY_ACCOUNT } = ROUTES;

const persistenceKey = version.split('.').join('-');

ReactDOM.render(
  <CubaAppProvider cubaREST={cubaREST} messagesMapping={messagesMapping} antdLocaleMapping={antdLocaleMapping}>
    <ErrorBoundary>
      <Provider {...appStore}>
        <ToastContainer transition={Slide} autoClose={8000} />
        <HashRouter>
          <Route
            path="/"
            children={({ location }) => {
              const CompanyAccountStore = LocalStorageUtils.read(`companyAccountStore${persistenceKey}`);
              if (location.pathname.includes(ROUTE_COMPANY_ACCOUNT)) {
                if (
                  !CompanyAccountStore ||
                  !(
                    CompanyAccountStore as unknown as {
                      companySessionId: string;
                    }
                  ).companySessionId
                ) {
                  return <Redirect to="/sign-in" />;
                } else {
                  return <CompanyAccount />;
                }
              } else {
                return <App />;
              }
            }}
          />
        </HashRouter>
      </Provider>
    </ErrorBoundary>
  </CubaAppProvider>,

  document.getElementById('root') as HTMLElement,
);

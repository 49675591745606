import React from 'react';
import { injectIntl } from 'react-intl';

import cn from 'classnames';
import styles from './StepsBlock.module.scss'; 

type TProps = {
  intl: any;
  activeStep: number;
}

const StepsBlock: React.FC<TProps> = ({ activeStep, intl }) => {
  return (
    <div className={styles['StepsBlock']}>
    {/* 1 step */}
    <div className={cn(
      styles['StepsBlock__item'],
      activeStep === 1 && styles['active'],
      activeStep !== 1 && styles['checked'],
    )}>
      <b>1</b>
      <span>{intl.formatMessage({ id: 'touristData.title' })}</span>
    </div>

    {/* line */}
    <div className={styles['StepsBlock__line']} />

    {/* 2 step */}
    <div className={cn(
      styles['StepsBlock__item'],
      activeStep === 2 && styles['active'],
      activeStep === 3 && styles['checked'],
    )}>
      <b>2</b>
      <span>{intl.formatMessage({ id: 'tourData.title' })}</span>
    </div>

    {/* line */}
    <div className={styles['StepsBlock__line']} />

    {/* 3 step */}
    <div className={cn(
      styles['StepsBlock__item'],
      activeStep === 3 && styles['active']
    )}>
      <b>3</b>
      <span>{intl.formatMessage({ id: 'payment.formTitle' })}</span>
    </div>
  </div>
  )
}
export default injectIntl(StepsBlock);

import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import cn from 'classnames'

import { HttpResponse } from '@api';
import { ConfirmationService } from '@services';
import UserStore from '@stores/UserStore';

import styles from '@components/common/CodeSubmitForm/CodeSubmitForm.module.scss';

const {
  confirmEmailForSpecialResolutionOther,
  getConfirmationCode,
  requestConfirmCode,
  checkConfirmationCode,
  requestAuth,
} = ConfirmationService;

interface Props {
  intl: IntlShape;
  locale: string | undefined;
  storedReplyMessageId: UserStore['replyMessageId'];
  storedSessionId: string;
  touched: UserStore['touched'];
  errors: UserStore['errors'];
  status: UserStore['authStatus'];
  storedCode: UserStore['code'];
  codeStatus: UserStore['codeStatus'];
  timer: UserStore['timer'];
  setErrors: UserStore['setErrors'];
  setEmailValue: UserStore['setEmail'];
  getConfirmationCode: typeof getConfirmationCode | typeof requestConfirmCode;
  setCode: UserStore['setCode'];
  setTouched: UserStore['setTouched'];
  setAuthStatus: UserStore['setAuthStatus'];
  setTimer: UserStore['setTimer'];
  checkConfirmationCode: typeof checkConfirmationCode | typeof requestAuth;
  confirmEmailForSpecialResolutionOtherRequest: typeof confirmEmailForSpecialResolutionOther;
  setCodeStatus: UserStore['setCodeStatus'];
  setSessionId: UserStore['setSessionId'];
  successFunction: () => void;
  isDisabled: boolean;
}

const TranzitCodeSubmitForm: React.FC<Props> = props => {
  const {
    intl,
    storedReplyMessageId,
    codeStatus,
    errors,
    status,
    storedCode,
    setErrors,
    locale,
    setCode,
    confirmEmailForSpecialResolutionOtherRequest,
    setCodeStatus,
    setSessionId,
    successFunction,
    isDisabled,
  } = props;

  const t = intl.formatMessage;

  const handleCodeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
  };

  const handleCodeSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (storedReplyMessageId && storedReplyMessageId) {
      confirmEmailForSpecialResolutionOtherRequest(locale, storedCode, storedReplyMessageId)
        .then(({ error, data }: HttpResponse) => {
          if (error) {
            setErrors({ code: error });
            throw new Error(error);
          } else {
            setCodeStatus({
              status: data.success,
              message: data.replyMessage,
            });
            if (data.sessionId) {
              setSessionId(data.sessionId);
            }
            return successFunction();
          }
        })
        // eslint-disable-next-line
        .catch((error: any) => console.error(error));
    }
  };

  return (
    <>
      {/*CODE SUBMIT FORM*/}
      <form className={styles['code-submit-form']} onSubmit={handleCodeSubmit} autoComplete='off'>
        <div className={styles['code-submit-form__subtitle']}>{t({ id: 'common.codeLabel' })}</div>

        <div className={styles['code-submit-form__wrapper']}>
          <label htmlFor='code' className={styles['code-submit-form__input-label']}>
            <input
              className={cn(styles['code-submit-form__input-code'], errors?.code ? styles['code-submit-form__error-input'] : null)}
              type='number'
              placeholder='12345'
              onChange={handleCodeChange}
              value={storedCode}
              maxLength={5}
              minLength={5}
              name='code'
              disabled={isDisabled}
              required
            />
          </label>

          <button type='submit' className={styles['code-submit-form__button']} disabled={isDisabled}>
            {t({ id: 'common.loginBtn' })}
          </button>
        </div>
        <div className={styles['code-submit-form__descriptor']}>{status?.message}</div>

        {errors?.code ? (
          <div className={styles[`code-submit-form__code-info-error`]}>{errors?.code}</div>
        ) : (
          <div className={styles[`code-submit-form__code-info`]}>{codeStatus?.message}</div>
        )}
      </form>
    </>
  );
};

export default injectIntl(TranzitCodeSubmitForm);

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Modal, Button, Typography } from 'antd';

import { useAppStore } from '@store';
import styles from './ParkSelectionModal.module.scss';

const { Title } = Typography;

interface Props {
  isPlug?: boolean;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  alertMessage?: string;
  directorates: any,
}
const hardcode = {
  name: 'name',
  address: 'address',
  contactPhone: 'phone',
  email: 'email'
}

const ParkSelectionModal: React.FC<any> = (props: Props) => {
  const {
    directorateStore: { directorates },
  } = useAppStore()
  const { visible, onOk, onCancel, isPlug = false, alertMessage } = props;
  const { name = '', address = '', contactPhone = '', email = '' } = directorates[0] ? directorates[0] : hardcode; // HARDCODE - only one directorate (no directorate id)

  return (
    <Modal
      centered={true}
      visible={visible}
      footer={[
        <Button
          key="submit"
          // type="primary"
          size="large"
          onClick={onOk}
          className={styles['ParkSelectionModal__ok-button']}
        >
          <FormattedMessage id="button.ok" />
        </Button>,
      ]}
      className={cn(styles['ParkSelectionModal'], isPlug ? styles['ParkSelectionModal__is-plug'] : '')}
      width={600}
      onCancel={onCancel}
    >
      {alertMessage !== '' ? (
        <>
          <Title level={2}>{alertMessage}</Title>
        </>
      ) : (
        <div className={styles['ParkSelectionModal__wrapper']}>
          <h2 className={styles['ParkSelectionModal__title']}>
            <span>
              <FormattedMessage id="main.modalTitle" />
            </span>
            <span>{name}</span>
          </h2>

          <div className={styles['ParkSelectionModal__contacts']}>
            <p className={styles['ParkSelectionModal__address']}>{address}</p>

            <div className={styles['ParkSelectionModal__links']}>
              <a className={styles['ParkSelectionModal__link']} href={`tel:${contactPhone}`}>
                {/* phone-icon */}
                <svg
                  className={styles['ParkSelectionModal__links-icon']}
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 513.64 513.64"
                >
                  <g>
                    <g>
                      <path
                        d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72
                      c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68
                      c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44
                      l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"
                      />
                    </g>
                  </g>
                </svg>

                <span className={styles['ParkSelectionModal__links-title']}>{contactPhone}</span>
              </a>

              <a className={styles['ParkSelectionModal__link']} href={`mailto:${email}`}>
                {/* mail-icon */}
                <svg
                  className={styles['ParkSelectionModal__links-icon']}
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <g>
                      <path
                        d="M467,76H45C20.137,76,0,96.262,0,121v270c0,24.885,20.285,45,45,45h422c24.655,0,45-20.03,45-45V121
                        C512,96.306,491.943,76,467,76z M460.698,106c-9.194,9.145-167.415,166.533-172.878,171.967c-8.5,8.5-19.8,13.18-31.82,13.18
                        s-23.32-4.681-31.848-13.208C220.478,274.284,64.003,118.634,51.302,106H460.698z M30,384.894V127.125L159.638,256.08L30,384.894z
                        M51.321,406l129.587-128.763l22.059,21.943c14.166,14.166,33,21.967,53.033,21.967c20.033,0,38.867-7.801,53.005-21.939
                        l22.087-21.971L460.679,406H51.321z M482,384.894L352.362,256.08L482,127.125V384.894z"
                      />
                    </g>
                  </g>
                </svg>

                <span className={styles['ParkSelectionModal__links-title']}>{email}</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default injectIntl(observer(ParkSelectionModal));

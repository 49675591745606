import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-184218169-1'); // tracking id from google analytics
}


type NameOfObject = string;

export const gaPageView = (page: NameOfObject) => {
  ReactGA.pageview(page);
}

export const gaModalView = (modal: NameOfObject) => {
  ReactGA.modalview(modal);
};
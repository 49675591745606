import * as React from 'react';
import { ChangeEvent } from 'react';
import { read, utils } from 'xlsx';

import { TRAVELER } from '@constants';
import { Tourist, Country, ExcelData, ExcelDataKeys } from '@typing/Traveler';
import { City } from '@typing/OrderData';
import { UUIDUtils } from '@utils';

const { getUUID } = UUIDUtils;
const {
  CITIZENSHIP,
  CITY,
  DOCUMENT_NR,
  DOCUMENT_SERIES,
  DOCUMENT_TYPE,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  PRIVILEGES,
  SECOND_NAME,
} = TRAVELER;

export const parseExcelFile = (reader: FileReader): ExcelData[] => {
  const fileData = reader.result;
  const workbook = read(fileData, { type: 'binary' });
  const entries = Object.entries(ExcelDataKeys);
  const csv = utils
    .sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]])
    .split('\n')
    .splice(5)
    .filter(value => value)
    .splice(1);
  const rowsArray = csv.map(item => {
    const value = item.split(',').filter(vItem => vItem !== null);
    value.splice(5, 1);
    return value;
  });

  const entriesArr = rowsArray.reduce((accumulator, row) => {
    const entry = row.reduce((acc, item, index) => {
      const key = entries[index][0];
      acc[key] = item;
      return acc;
    }, {} as ExcelData);

    accumulator.push(entry);

    return accumulator;
  }, [] as ExcelData[]);

  return entriesArr;
};

export const loadExcelFile = (event: ChangeEvent<HTMLInputElement>): FileReader => {
  const { files } = event.target;
  const reader = new FileReader();

  if (files?.length) {
    const file = files[0];
    const extension = file.name.split('.').pop()?.toLowerCase();

    if (extension?.includes('xlsx')) {
      reader.readAsBinaryString(file);
    } else {
      // eslint-disable-next-line
      console.error('Only .xlsx files are supported');
    }
  }
  return reader;
};

// don't need anymore - delete candidate
export const downLoadExcelFile = (): void => {
  const fileName = 'Шаблон на группу.xlsx';
  const url = `/docs/${fileName}`;
  const req = new XMLHttpRequest();

  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = () => {
    const blob = new Blob([req.response], { type: 'application/octet-stream' });
    const link = window.URL.createObjectURL(blob);
    const linkElement = document.createElement('a');

    linkElement.setAttribute('download', fileName);
    linkElement.setAttribute('href', link);
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  };
  req.send();
};

export const initMemberKeys = [
  CITIZENSHIP,
  CITY,
  DOCUMENT_NR,
  DOCUMENT_SERIES,
  DOCUMENT_TYPE,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  PRIVILEGES,
];

// don't need anymore - delete candidate
export const downLoadExcelFileMethod = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
  downLoadExcelFile();
};

export const handleChangeCheck = (
  e: any,
  pr: 'rules' | 'policy' | 'personalData',
  setRules: any,
  touristCreatedIn: any,
  rules: any,
  setPolicy: any,
  policy: any,
  setPersonalData: any,
  personalData: any,
) => {
  switch (pr) {
    case 'rules': {
      setRules(touristCreatedIn, !rules[touristCreatedIn]);
      break;
    }
    case 'policy': {
      setPolicy(touristCreatedIn, !policy[touristCreatedIn]);
      break;
    }
    case 'personalData': {
      setPersonalData(touristCreatedIn, !personalData[touristCreatedIn]);
      break;
    }
  }
};

const mapExcelDataToTourist = async (
  excelData: ExcelData,
  touristCreatedIn: any,
  getCities: any,
  countries: Country[],
): Promise<Tourist | null> => {
  const query = excelData[CITY];

  return new Promise(resolve => {
    if (excelData[LAST_NAME] !== '' && excelData[FIRST_NAME] !== '') {
      const excelCitizenship = excelData[CITIZENSHIP];

      const tourist = {
        [LAST_NAME]: excelData[LAST_NAME] ?? '',
        [FIRST_NAME]: excelData[FIRST_NAME] ?? '',
        midName: excelData[SECOND_NAME] ?? '',
        cityUUID: '',
        docType: 'docType',
        id: getUUID(),
        isPrivilegeModalVisible: false,
        mainMember: false,
        noMidName: !excelData[SECOND_NAME],
        number1: '',
        number2: '',
        pCategory: excelData['privileges'] ?? '',
        phoneNumber: excelData[PHONE_NUMBER] ?? '',
        [CITIZENSHIP]: '',
        [CITY]: '',
        [EMAIL]: excelData[EMAIL] ?? '',
        createdIn: touristCreatedIn,
        isEmployee: false,
      };

      if (excelCitizenship && countries.find((country: Country) => country.name === excelCitizenship)) {
        tourist[CITIZENSHIP] = excelCitizenship;
      }

      if (query) {
        getCities(query).then((cities: City) => {
          tourist[CITY] = cities[0]?.fullName;
          tourist.cityUUID = cities[0]?.cityUUID;

          resolve(tourist as Tourist);
        });
      } else {
        resolve(tourist as Tourist);
      }
    } else {
      resolve(null);
    }
  });
};

export const loadExcelFileMethod = (
  event: React.ChangeEvent<HTMLInputElement>,
  deleteGroup: any,
  touristCreatedIn: any,
  getCities: any,
  members: any,
  appendTourist: any,
  addMember: any,
  countries: Country[],
) => {
  const reader = loadExcelFile(event);

  reader.onload = () => {
    const excelData = parseExcelFile(reader);

    deleteGroup(touristCreatedIn); // очищаем список туристов, если уже были заполненные

    const touristsPromises = excelData.map(async value => {
      return mapExcelDataToTourist(value, touristCreatedIn, getCities, countries);
    });

    Promise.all(touristsPromises.map(promise => promise.then(tourist => tourist))).then(
      (tourists: (Tourist | null)[]) => {
        tourists
          .filter(tourist => tourist !== null)
          .forEach((tourist, index) => {
            if (index === 0) {
              // appendTourist(tourist, index);
              const targetIndex = members.findIndex((member: Tourist) => member.createdIn === touristCreatedIn);
              appendTourist(tourist, targetIndex);
            } else {
              // eslint-disable-next-line
              addMember(tourist).catch(console.error);
            }
          });
      },
    );
  };
};

export const convertEmployeeToMember = (employee: any, touristCreatedIn: any) => {
  const { userId, firstName, lastName, midName, cityId = '', countryId = '', regionId,  } = employee;

  const member = {
    id: userId,
    firstName,
    lastName,
    midName,
    isPrivilegeModalVisible: false,
    isEmployee: true,
    number1: '',
    number2: '',
    noMidName: !midName,
    cityUUID: cityId,
    countryUUID: countryId,
    mainMember: false,
    createdIn: touristCreatedIn,
    regionId: regionId ? regionId : null,
  };
  return member;
};

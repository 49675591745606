import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
    // геоданные(координаты полигонов территорий). id - айди ООПТ
    getProtectedTerritoryFile: (id: string, name: string, lang: string = ru): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getProtectedTerritoryFile`, { params: { id, name, lang } })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },
};

import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';
import { appStore } from '@store';
import { checkResponse } from './helpers';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
  // запрос на авторизацию для доступа к лк(личный кабинет). На емейл отправляется код.
  getConfirmationCode: (lang: string = ru, email: string): Promise<HttpResponse> => {
    return httpClient.get(`${REST_CURRENT}lkAuth`, { params: { lang, email } }).then((response: HttpResponse) => {
      const { data, error } = response;

      if (error) {
        return { data: null, error };
      } else {
        return { data, error };
      }
    });
  },

  // Запрос при нажатии на кнопку "выслать код" на странице оплаты.  На email отправляется код.
  requestConfirmCode: (lang: string = ru, email: string, sessionId: string): Promise<HttpResponse> => {
    return httpClient
      .get(`${REST_CURRENT}requestConfirmCode`, { params: { lang, email, sessionId } })
      .then((response: HttpResponse) => {
        const { data } = response;

        return checkResponse(data);
      });
  },

  // Подтвердить код. В случае успеха в ответ придет - sessionId, с помощью которой можно работать с лк.(личный кабинет)
  checkConfirmationCode: (lang: string = ru, email: string, confirmationCode: string): Promise<HttpResponse> => {
    return httpClient
      .get(`${REST_CURRENT}lkConfirmCode`, { params: { lang, email, confirmationCode } })
      .then((response: HttpResponse) => {
        const { data, error } = response;

        if (error) {
          return { data: null, error };
        } else {
          return { data, error };
        }
      });
  },

  // Подтвердить емейл.
  // Сессия, емейл и код подтверждения проверяется. И если они совпали, то возвращается успех и
  // если аккаунт(пользователь) уже существует - в ответ придет новый(основной) sessionId. Если нет - то старый.
  // Если не совпали, то приходит ответное уведомление об ошибке.
  requestAuth: (
    lang: string = ru,
    email: string,
    confirmationCode: string,
    sessionId: string,
  ): Promise<HttpResponse> => {
    return httpClient
      .get(`${REST_CURRENT}auth`, { params: { lang, email, confirmationCode, sessionId } })
      .then((response: HttpResponse) => {
        const { data, error } = response;

        if (error) {
          return { data, error: data.replyMessage };
        } else {
          appStore.orderDataStore.setConfirmation(data);
          return { data, error };
        }
      });
  },

  // Подтвердить емейл для разрешения "Иное"(Транзитное).
  confirmEmailForSpecialResolutionOther: (
    lang: string = ru,
    confirmationCode: string,
    id: string,
  ): Promise<HttpResponse> => {
    return httpClient
      .get(`${REST_CURRENT}confirmEmailForSpecialResolutionOther`, { params: { lang, confirmationCode, id } })
      .then((response: HttpResponse) => {
        const { data, error } = response;

        if (error) {
          return { data: null, error };
        } else {
          return { data, error };
        }
      });
  },
};

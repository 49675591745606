import React from 'react';
import { Spin } from 'antd';

import styles from './CenteredLoader.module.scss';

interface IProps {
  text?: string;
}

const CenteredLoader: React.FC<IProps> = ({ text = 'Loading ...' }) => (
  <div className={styles['CenteredLoader']}>
    <Spin tip={text} size="large" />
  </div>
);

export default CenteredLoader;

import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SERVER_HOST } from '@configuration/config';

import styles from './Footer.module.scss';

const injectedProps = inject(({ directorateStore, orderDataStore }) => ({
  directorateStore: directorateStore,
  orderDataStore: orderDataStore,
}));

const Footer: React.FC<any> = observer((props: any) => {
  useEffect(() => {
    props.directorateStore.loadDirectorates();
  }, []);
  const { directorates } = toJS(props.directorateStore); // directorates data
  const currentDirectorate = directorates[0]; // HARDCODE - only one directorate (no directorate id)
  const currentOOPT = props.orderDataStore.oopt;

  return (
    <footer className={styles['Footer']}>
      <div className={styles['Footer__content']}>
        <ul className={styles['Footer__nav']}>

          {/* <li className={cn(styles['Footer__nav-item'], props.small ? styles['Footer__nav-item--small'] : '')}> */}
          <li className={styles['Footer__nav-item']}>
            {/* Тарифы и льготы */}
            <a
              className={styles['Footer__nav-link']}
              href={`${SERVER_HOST}api/getFile?lang=ru&id=${currentDirectorate?.docTariffs}`}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id={'footer.tariffs'} />
            </a>
          </li>

          <li className={styles['Footer__nav-item']}>
            {/* Правила пребывания в парке */}
            <a
              className={styles['Footer__nav-link']}
              href={`${SERVER_HOST}api/getFile?lang=ru&id=${currentOOPT?.docParkRules}`}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id={'footer.rules'} />
            </a>
          </li>

          <li className={styles['Footer__nav-item']}>
            {/* Конфиденциальность */}
            <a
              className={styles['Footer__nav-link']}
              href={`${SERVER_HOST}api/getFile?lang=ru&id=${currentDirectorate?.docConfidentiality}`}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id={'footer.confidentiality'} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
});

export default injectIntl(injectedProps(Footer));

import React from 'react';

interface IProps {
  classNameProp?: string;
}

const Enter: React.FC<IProps> = ({ classNameProp }) => {
  return (
    <svg className={classNameProp} height="512" viewBox="0 0 512 512" width="512">
      <g id="Solid">
        <path d="m260.52 329.539a24 24 0 0 0 33.941 33.941l90.51-90.51a24 24 0 0 0 0-33.941l-90.51-90.509a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941l49.539 49.539h-262.059a24 24 0 0 0 -24 24 24 24 0 0 0 24 24h262.059z"/>
        <path d="m448 24h-224a40 40 0 0 0 -40 40v32a24 24 0 0 0 48 0v-24h208v368h-208v-24a24 24 0 0 0 -48 0v32a40 40 0 0 0 40 40h224a40 40 0 0 0 40-40v-384a40 40 0 0 0 -40-40z"/>
      </g>
    </svg>
  );
};

export default Enter;
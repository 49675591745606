import React, { createContext, useContext } from 'react';

import AppStore from '@stores/AppStore';

export const appStore = new AppStore();
export const AppStoreContext = createContext(appStore);

/**
 *Function component hook
 */
export function useAppStore(): AppStore {
  const store = useContext(AppStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}

/**
 * Class component HOC
 */
export const injectStore =
  (Component: any): typeof Component =>
  (props: any) => {
    return <Component {...props} appStore={useAppStore()} />;
  };

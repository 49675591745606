import React from 'react';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { SERVER_HOST } from '@configuration/config';
import { useAppStore } from '@store';
import styles from './CookieBanner.module.scss';

const CookieBanner: React.FC<any> = (props: any) => {
  const {
    userStore: { setCookie },
    orderDataStore: {
      oopt: { docConfidentialityId },
    },
  } = useAppStore();

  const { intl } = props;

  return (
    <div className={styles['CookieBanner']}>
      <div className={styles['CookieBanner__close']} onClick={setCookie} />

      <p className={styles['CookieBanner__text']}>
        {intl.formatMessage({ id: 'cookieBanner.message' })}
        &nbsp;
        <a href={`${SERVER_HOST}api/getFile?lang=ru&id=${docConfidentialityId}`} target="_blank" rel="noreferrer">
          <strong>{intl.formatMessage({ id: 'cookieBanner.more' })}</strong>
        </a>
      </p>

      <button className={styles['CookieBanner__button']} onClick={setCookie}>
        {intl.formatMessage({ id: 'cookieBanner.confirm' })}
      </button>
    </div>
  );
};

export default injectIntl(observer(CookieBanner));

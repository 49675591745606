import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
    // Получение списка ООПТ и краткой инфо о ООПТ
    getOoptsShort: (lang: string = ru): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getProtectedTerritory`, { params: { lang } })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Получение начальных данных при открытии сайта
    getAllAppSettings: (lang: string = ru): Promise<HttpResponse> => {
        return httpClient.get(`${REST_CURRENT}getAllOptions`, { params: { lang } }).then((response: HttpResponse) => {
            const { data, error } = response;
            if (error) {
                return { data: null, error };
            } else {
                return { data, error };
            }
        });
    },

    // геоданные(координаты полигонов территорий). id - айди ООПТ
    getGeoData: (id: string): Promise<HttpResponse> => {
        return httpClient.get(`${REST_CURRENT}getGeoData`, { params: { id } }).then((response: HttpResponse) => {
            const { data, error } = response;
            if (error) {
                return { data: null, error };
            } else {
                return { data, error };
            }
        });
    },

    // Получение файлов. Id - UUID файла
    getFile: (lang: string = ru, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getFile`, {
                params: { lang, id },
                responseType: 'blob',
                headers: {
                    Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                },
            })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Загрузка объявления для пользователей сайта. Вызывается в самом начале открытия сайта.
    getAlertForUsers: (lang: string = ru): Promise<any> => {
        return httpClient
            .get(`${REST_CURRENT}getAlertForUsers`, {
                params: { lang },
                responseType: 'text',
                headers: {
                    Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                },
            })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },
};

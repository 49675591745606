export default {
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  SECOND_NAME: 'midName',
  CITIZENSHIP: 'country',
  PHONE_NUMBER: 'phoneNumber',
  CITY: 'city',
  DOCUMENT_TYPE: 'docType',
  EMAIL: 'email',
  PRIVILEGES: 'pCategory',
  CODE: 'code',
  COUNTRY_NAME: 'c_name',
  DOCUMENT_NR: 'number2',
  DOCUMENT_SERIES: 'number1',
  SOCIAL_DISCOUNT: 'percent',
  SOCIAL_CATEGORY_NAME: 'name',
};

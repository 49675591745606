import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';

import { API } from '@constants';
import { ProtectedTerritorySettings } from '@typing/Settings';

const {
  LANGUAGES: { ru },
} = API;

export default class SettingsStore {
  @observable globalErrors: {
    status: boolean;
    message: string | null;
  } = { status: false, message: null };

  @persist language: string = ru;

  @persist('list')
  @observable
  protectedTerritoriesSettings?: ProtectedTerritorySettings[] = undefined; // список парков

  @observable isProtectedTerritoriesSettingsLoading: boolean = false;

  @action
  setDefault = () => {
    runInAction(() => {
      this.language = ru;
      this.protectedTerritoriesSettings = undefined;
      this.isProtectedTerritoriesSettingsLoading = false;
    });
  };

  @action
  setGlobalErrors = (message: string | null) => {
    this.globalErrors.status = true;
    this.globalErrors.message = message;
  };
}

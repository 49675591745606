import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
    // Добавить заявку-разрешение
    addTravelerRequest: ({
        lang = ru,
        jsonFullData,
    }: {
        lang?: string;
        jsonFullData: string;
    }, sessionId: string): Promise<HttpResponse> => {
        return httpClient
            .post(`${REST_CURRENT}addTravelerRequest2`, { lang, jsonFullData }, sessionId ? { params: { sessionId } } : {params: {}})
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Добавить заявку-разрешение "Иное"(Транзитное).
    addSpecialResolutionOther: ({
        lang = ru,
        jsonFullData,
    }: {
        lang?: string;
        jsonFullData: string;
    }): Promise<HttpResponse> => {
        return httpClient
            .post(`${REST_CURRENT}addSpecialResolutionOther`, { lang, jsonFullData })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Добавить заявку-разрешение "Иное"(Транзитное).
    getAllActiveTravelsRequests: (email: string, lang: string = ru): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getAllActiveTravelsRequests`, { params: { lang, email } })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Поиск города
    getPossibleCities: (name: string, lang: string = ru): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getPossibleCities`, { params: { lang, name } })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Оплатить заявку(в том числе при обращении из личного кабинета).
    getPaymentURL: (sessionId: string, travelerRequestId: string, lang: string = ru): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}payTravelerRequest`, { params: { sessionId, travelerRequestId, lang } })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },
};


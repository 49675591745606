import { action, observable } from 'mobx';

import { LegalFormOptions } from '@typing/enums';
import { CompanyAccountService } from '@services';

const { registerCompany, getCompanyAllOptions } = CompanyAccountService;

interface ICompanyData {
  organizationType: string[];
  legalForm: LegalFormOptions.company | LegalFormOptions.entrepreneur;
  govNumber: string;
  firmName: string;
  fullCompanyName: string;
  shortCompanyName: string;
  legalAddress: string;
  postalAddress: string;
  actualAddress: string;
  inn: string;
  kpp: string;
  ogrn: string;
  ogrnip: string;
  okved: string;
  bankName: string;
  accountNumber: string;
  corAccountNumber: string;
  bik: string;
  adminName: string;
  adminSurname: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  agreement: string[];
  allRulesAgreement: string[];
}

export default class CompanyRegistrationStore {
  @observable companyData: ICompanyData = {
    organizationType: [],
    legalForm: LegalFormOptions.company, //юрлицо default
    govNumber: '',
    firmName: '',
    fullCompanyName: '',
    shortCompanyName: '',
    legalAddress: '',
    postalAddress: '',
    actualAddress: '',
    inn: '',
    kpp: '',
    ogrn: '',
    ogrnip: '',
    okved: '',
    bankName: '',
    accountNumber: '',
    corAccountNumber: '',
    bik: '',
    adminName: '',
    adminSurname: '',
    phone: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    agreement: [],
    allRulesAgreement: [],
  };

  @observable legalFormOptions: { label: string; value: string }[] = [];

  @observable orgTypeOptions: { label: string; value: string }[] = [];

  @observable files: File[] = [];

  @observable isPostDataSuccess: boolean = false;

  @action
  getCompanyRegistrationOptions = () => {
    getCompanyAllOptions().then(response => {
      const { data } = response;
      const legalFormOptions: { label: string; value: string }[] = [];
      const orgTypeOptions: { label: string; value: string }[] = [];

      if (data) {
        const { replyMessage } = data;
        JSON.parse(replyMessage).forEach((item: any) => {
          if (item.objectType === 'JuristicCompanyForm') {
            legalFormOptions.push({ label: item.name, value: item.id });
          }

          if (item.objectType === 'JuristicCompanyType') {
            orgTypeOptions.push({ label: item.name, value: item.id });
          }
        });
      }

      this.legalFormOptions = [...legalFormOptions];
      this.orgTypeOptions = [...orgTypeOptions];
    });
  };

  @action
  setCompanyData = (data: ICompanyData) => {
    this.companyData = data;
  };

  @action
  setFiles = (files: File[]) => {
    this.files = [...files];
  };

  @action
  setLegalForm = (legalForm: LegalFormOptions.entrepreneur | LegalFormOptions.company) => {
    this.companyData.legalForm = legalForm;
  };

  @action
  postCompanyData = async (values: ICompanyData) => {
    const formData = new FormData();

    const jsonFullData = [
      {
        objectType: 'JuristicAccount',
        administrator: true,
        login: values.email,
        password: values.password,
        juristicCompany: [],
        userName: `${values.adminName} ${values.adminSurname}`,
      },
      {
        objectType: 'JuristicCompany',
        phone: values.phone,
        docOGRN: values.ogrn,
        docINN: values.inn,
        companyNumber: values.govNumber,
        name: values.fullCompanyName,
        shortName: values.shortCompanyName,
        firmName: values.firmName,
        juristicCompanyFormUUID: values.legalForm,
        juristicCompanyTypesUUID: [...values.organizationType],
        docOKVED: values.okved,
        docKPP: values.kpp,
        docOGRNIP: values.ogrnip,
        juristicAddress: values.legalAddress,
        postAddress: values.postalAddress,
        realAddress: values.actualAddress,
        bankName: values.bankName,
        bankRS: values.accountNumber,
        bankCORRS: values.corAccountNumber,
        bankBIK: values.bik,
      },
    ];

    formData.append('jsonFullData', JSON.stringify(jsonFullData));

    for (let i = 0; i < this.files.length; i++) {
      formData.append(`file[${i}]`, this.files[i]);
    }

    return registerCompany(formData);
  };

  @action
  setPostDataStatus = (status: boolean) => {
    this.isPostDataSuccess = status;
  };
}

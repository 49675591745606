import React from 'react';
import Image from 'react-bootstrap/Image';

import updateIcon from '@static/icons/update-icon.svg';

import styles from './Captcha.module.scss';

interface IProps {
  captcha: string;
  onClickUpdate: () => void;
  intl: any;
}

const Captcha: React.FC<IProps> = ({ captcha, onClickUpdate, intl }) => (
  <div className={styles['Captcha']}>
    <label className={styles['Captcha__label']}>{intl.formatMessage({ id: 'signIn.captcha' })}</label>
    <Image src={`data:image/jpeg;base64, ${captcha}`} className={styles['Captcha__img']} />

    <div className={styles['Captcha__update']}>
      <a className={styles['Captcha__update-link']} onClick={onClickUpdate}>
        <Image src={updateIcon} className={styles['Captcha__update-img']} />
        <span>{intl.formatMessage({ id: 'signIn.updateCaptcha' })}</span>
      </a>
    </div>
  </div>
);

export default Captcha;

import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';

export default class SelectedUserTerritoryStore {
    @persist @observable territoryId: string = '';

    @persist @observable documentTypeName: 'tariffs' | 'rules' = 'tariffs';

    @persist @observable defaultTerritoryOoptId: string = 'e5498be5-b1ce-c1a9-03be-fe01cfa2c0c1';

    @action
    setTerritoryId = async (territoryId: string): Promise<boolean> => {
        runInAction(() => {
            this.territoryId = territoryId;
        });
        return !!this.territoryId;
    };

    @action
    setDocumentTypeName = async (documentTypeName: 'tariffs' | 'rules') => {
        runInAction(() => {
            this.documentTypeName = documentTypeName;
        });
    };

    @action
    setDefaultTerritoryOoptId = async (defaultTerritoryOoptId: string) => {
        runInAction(() => {
            this.defaultTerritoryOoptId = defaultTerritoryOoptId;
        });
    };
}

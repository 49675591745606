import { toast } from 'react-toastify';

import { appStore } from '@store';
import { HttpResponse } from '@api';

export default async function checkResponse(response: any): Promise<HttpResponse> {
  const { success, replyMessage } = response;
  const {
    userStore: { setErrors, setAuthStatus },
  } = appStore;

  if (success) {
    setErrors({ email: null });
    setAuthStatus({
      status: 'success',
      message: replyMessage,
    });
    return { error: undefined, data: response };
  } else {
    toast.error(replyMessage);
    setErrors({ email: replyMessage });
    return {
      error: replyMessage,
      data: response,
    };
  }
}

import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import dayjs from 'dayjs';
import moment from 'moment';
import lodashGet from 'lodash.get';
import lodashFindIndex from 'lodash.findindex';

import { UUIDUtils } from '@utils';
import { Tourist } from '@typing/Traveler';
import { OOPT, PERMISSIONS } from '@constants';
import { initMemberKeys } from '@pages/TouristsDataScreen/helpers/helpers';
import { isLogging } from '@configuration/config';
import { QuickOopt } from '@typing/QuickOopt';
import { OrderDataService, ConfirmationService } from '@services';
import { IVillage, ITransport, ICamp } from '@typing/Trip';
import {
  IPostOrderTranzitData,
  CreatedIn,
  IPostOrderData,
  ITargetLocation,
  City,
  PermissionTypeValue,
  PermissionType,
} from '@typing/OrderData';
import { IServerResponse } from '@typing/Services';
import { COUNTRIES, TOURIST, PRIVILEGES_CONSTANTS } from '@constants';

interface IOopt {
  id: string;
  name: string;
  name2: string;
  objType: string;
  territories: [];
  contactHttpLink: string;
  backgroundId?: string;
}

const { getUUID } = UUIDUtils;
const { DEFAULT_TERRITORY_OOPT_ID, DEFAULT_TERRITORY_OOPT_NAME, DEFAULT_TERRITORY_OOPT_NAME_CASE } = OOPT;
const { PERMISSION_TYPE_TRANZIT, PERMISSION_TYPE_TOUR } = PERMISSIONS;
const { addSpecialResolutionOther, addTravelerRequest, getAllActiveTravelsRequests, getPossibleCities, getPaymentURL } =
  OrderDataService;
const {
  requestConfirmCode,
  requestAuth,
  getConfirmationCode,
  checkConfirmationCode,
  confirmEmailForSpecialResolutionOther,
} = ConfirmationService;

export const getDateStringDMY = (date: Date | null) => {
  return date
    ? `${('0' + new Date(date).getDate()).slice(-2)}.${('0' + (new Date(date).getMonth() + 1)).slice(-2)}.${new Date(
        date,
      ).getFullYear()}`
    : null;
};

export const individual: Tourist = {
  id: getUUID(),
  lastName: '',
  firstName: '',
  midName: '',
  country: '',
  docType: '',
  number1: '',
  number2: '',
  city: '',
  cityUUID: '',
  phoneNumber: '',
  email: '',
  pCategory: '',
  noMidName: false,
  mainMember: true,
  isPrivilegeModalVisible: false,
  isEmployee: false,
};

const defaultMembers: Tourist[] = [
  { ...individual, createdIn: 'mainService' },
  {
    ...individual,
    createdIn: 'companyAccount',
    id: getUUID(),
  },
];

const initOopt: IOopt = {
  id: DEFAULT_TERRITORY_OOPT_ID,
  name: DEFAULT_TERRITORY_OOPT_NAME,
  name2: DEFAULT_TERRITORY_OOPT_NAME_CASE,
  objType: 'objType',
  territories: [],
  contactHttpLink: '',
};

export default class OrderDataStore {
  @persist
  @observable
  currentBackground: string = '';

  @persist
  @observable
  createdIn: string = 'mainService';

  @observable
  prevOoptId: string = '';

  @observable
  maxInGroup: number = 40; // сколько максимум человек может быть добавлено

  @persist('object')
  @observable
  oopt: QuickOopt = { ...initOopt }; // выбранный парк

  @persist('object')
  @observable
  pointsTerrRou7: {} = {}; // координаты

  @persist
  @observable
  visitCount: number = 0;

  @persist
  @observable
  isGroup: boolean = false; // признак группы

  @persist('list')
  @observable
  members: Tourist[] = defaultMembers; // личные данные из формы

  @persist('object')
  @observable
  session: { sessionId: string; travelerRequestId: string } = {
    sessionId: '',
    travelerRequestId: '',
  };

  @observable
  isPostOrderLoading: boolean = false;

  @observable
  permissionType: PermissionTypeValue = PERMISSION_TYPE_TOUR;

  permissionTypes: PermissionType[] = [
    {
      label: 'Туризм и отдых',
      value: PERMISSION_TYPE_TOUR,
    },
    {
      label: 'Транзит до населённого пункта',
      value: PERMISSION_TYPE_TRANZIT,
    },
  ];

  @persist
  @observable
  emailMain: string | undefined = ''; // мэйл, на который регистрируется заявка

  @observable
  isPostEmailLoading: boolean = false;

  @observable
  isPostCodeLoading: boolean = false;

  @persist('list')
  @observable
  cities: City[] = [
    // поиск городов
    {
      cityUUID: '',
      fullName: '',
    },
  ];

  @observable
  isCitiesLoading: boolean = false;

  @persist('object')
  @observable
  confirmation: any = undefined; // подтверждения (см. описание РЕСТ-АПИ - как создается и регистрируется заявка + почта)

  @persist('object')
  @observable
  confirmationCodeRes: any = {};

  @persist('object')
  @observable
  confirmationEmailRes: any = {};

  @observable
  isAllDataCollected: boolean = false;

  @persist
  @observable
  rulesLink?: string = undefined;

  @persist('object')
  @observable
  resultGetPaymentUrl: IServerResponse | undefined | {} = {}; // url для оплаты

  @observable
  isPaymentUrlLoading: boolean = false;

  @persist('object')
  @observable
  campsGeoData: {} = {}; // все стоянки с координатами

  @persist('object')
  @observable
  rules = {
    mainService: false,
    companyAccount: false,
  };

  @persist('object')
  @observable
  allRules = {
    mainService: false,
    companyAccount: false,
  };

  @persist('object')
  @observable
  policy = {
    mainService: false,
    companyAccount: false,
  };

  @persist('object')
  @observable
  personalData = {
    mainService: false,
    companyAccount: false,
  };

  @persist('list')
  @observable
  collectedPostData: any[] = [];

  @observable
  isCommentValid: boolean = true;

  @persist('object')
  @observable
  docCondition: { [x: number]: { srLength: number; nrLength: number } } = {
    0: { srLength: 4, nrLength: 6 },
  };

  @persist
  @observable
  lastPermissionCreatedIn: CreatedIn | null = null;

  @action
  setCreatedIn = (service: string) => {
    this.createdIn = service;
  };

  @action
  setFirstVisit = (): void => {
    this.visitCount = this.visitCount + 1;
  };

  @action
  setIsCitiesLoading = (isCitiesLoading: boolean) => {
    this.isCitiesLoading = isCitiesLoading;
  };

  @action
  setCities = (cities: City[]) => {
    this.cities = cities;
  };

  @action
  setLastPermissionCreatedIn = (createdIn: CreatedIn | null) => {
    this.lastPermissionCreatedIn = createdIn;
  };

  @action
  setPermissionType = (permissionType: PermissionTypeValue): void => {
    this.permissionType = permissionType;
  };

  @action
  requestConfirmCode = (lang: string, email: string, sessionId: string) => {
    return requestConfirmCode(lang, email, sessionId);
  };

  @action
  getConfirmationCode = (lang: string, email: string) => {
    return getConfirmationCode(lang, email);
  };

  @action
  checkConfirmationCode = (lang: string, email: string, confirmationCode: string) => {
    return checkConfirmationCode(lang, email, confirmationCode);
  };

  @action
  requestAuth = async (lang: string, email: string, confirmationCode: string, sessionId: string) => {
    return requestAuth(lang, email, confirmationCode, sessionId);
  };

  @action
  confirmEmailForSpecialResolutionOther = async (lang: string, confirmationCode: string, id: string) => {
    return confirmEmailForSpecialResolutionOther(lang, confirmationCode, id);
  };

  @action
  postOrderTranzit = async (props: IPostOrderTranzitData): Promise<any> => {
    const {
      resolutionData,
      villages,
      // members,
      settings,
      createdIn,
    } = props;
    const travelMembers = this.members.filter((member: Tourist) => member.createdIn === createdIn);

    const documentId = (i: number) => {
      const documentItem = settings?.docType?.find((item: any) => item.name === travelMembers[i].docType);
      return documentItem ? documentItem.id : null;
    };

    const privilegeId = (i: number) => {
      const privilegeItem = settings?.pCategory?.find((item: any) => item.name === travelMembers[i].pCategory);
      return privilegeItem ? privilegeItem.id : null;
    };

    const villagesData = villages.map((item: IVillage) => {
      const { beginDate, endDate, vehicle, vehicleModel, vehicleNumber, reasonForVisit, id } = item;
      return {
        objectType: 'Parking',
        beginDateString: dayjs(beginDate as Date).format('DD.MM.YYYY'),
        endDateString: dayjs(endDate as Date).format('DD.MM.YYYY'),
        transportTypeUUID: vehicle,
        transportModel: vehicleModel || null,
        transportNumber: vehicleNumber || null,
        villageUUID: id,
        reasonForVisit,
      };
    });

    // WARNING - пока не удалять (закоменчено 28.07.2021)
    // if (!this.isGroup) {
    //   runInAction('setMainMember', () => {
    //     this.members = [this.members[0]];
    //   });
    // }

    const travelersData =
      travelMembers &&
      travelMembers.map((member: Tourist, index: number) => {
        return {
          objectType: 'Traveler',
          isMainTraveler: index === 0,
          lastName: member.lastName,
          firstName: member.firstName,
          midName: member.midName,
          phone: member.phoneNumber ? member.phoneNumber.replace(/\D+/g, '') : null, // при отправке удаляем маску, сервер принимает только номер
          email: member.email,
          documentUUID: documentId(index) || null,
          docParam1: member.number1,
          docParam2: member.number2,
          docParam3: '_',
          privilegeCategoryUUID: privilegeId(index) || '',
          countryUUID: null,
          cityUUID: null,
          isGuide: false,
        };
      });

    const bodyMembers = [resolutionData, ...villagesData, ...travelersData];

    runInAction('setCollectedPostData', () => {
      this.setCollectedPostData(bodyMembers);
    });

    runInAction('isAllDataCollected', () => {
      this.isAllDataCollected = true;
      this.confirmationCodeRes = false;
      this.confirmationEmailRes = false;
      this.confirmation = {};
      this.isPostOrderLoading = true;
    });

    const response = await addSpecialResolutionOther({ jsonFullData: JSON.stringify(bodyMembers) })
      .then(({ error, data }) => {
        if (!error) {
          runInAction('setResultToSession', () => {
            this.session = { ...data };
            this.isPostOrderLoading = false;
          });
          return data;
        }
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(`Add Resolution Other: Error`, err);
      });

    this.isPostOrderLoading = false;

    return response;
  };

  @action
  postOrder = async (props: IPostOrderData, sessionId: string): Promise<any> => {
    const {
      comment,
      camps,
      transport,
      routes,
      beginDate,
      endDate,
      settings,
      isCampsRequired,
      isTransportRequired,
      createdIn,
    } = props;

    const travelMembers = this.members.filter(
      // (member: Tourist) => member.createdIn === createdIn && !member.isEmployee
      (member: Tourist) => member.createdIn === createdIn,
    );

    const documentId = (i: number) => {
      const documentItem = settings?.docType?.find((item: any) => item.name === travelMembers[i].docType);
      return documentItem ? documentItem.id : null;
    };

    const countryId = (i: number, memberCountryId?: string) => {
      const countryItem = settings?.country?.find((item: any) => {
        if (!memberCountryId) {
          return item.name === travelMembers[i].country;
        } else {
          return item.id === memberCountryId;
        }
      });
      return countryItem ? countryItem.id : null;
    };

    const privilegeId = (i: number) => {
      const privilegeItem = settings?.pCategory?.find((item: any) => item.name === travelMembers[i].pCategory);
      return privilegeItem ? privilegeItem.id : null;
    };

    const privilegeForEmployee = (member: any) => {
      if (member?.regionId === TOURIST.IRK_REGION_UUID) {
        return PRIVILEGES_CONSTANTS.IRK_REGION_PRIVILEGE_UUID;
      } else if (member.countryUUID === COUNTRIES.COUNTRY_RUSSIA_ID) {
        return PRIVILEGES_CONSTANTS.RUSSIA_PRIVILEGE_UUID;
      } else {
        return PRIVILEGES_CONSTANTS.NO_BENEFITS_PRIVILEGE;
      }
    };

    const protectedTerritory = {
      objectType: 'TravelerRequest',
      protectedTerritoryUUID: this.oopt.id,
      beginDate: moment(beginDate as Date).valueOf(),
      endDate: moment(endDate as Date).valueOf(),
      beginDateString: getDateStringDMY(beginDate),
      endDateString: getDateStringDMY(endDate),
      comment: comment,
    };

    const territory =
      routes &&
      routes.map((route: any, index: number) => {
        return {
          objectIndex: index + 1,
          objectType: 'PlaceOfVisit',
          territoryName: this.oopt.territories[route.pointIndex]?.name || null,
          routeUUID: this.oopt.territories[route.pointIndex]?.routes[route.routeIndex]?.id || null,
          beginDate: moment(route.beginDate as Date).valueOf(),
          endDate: moment(route.endDate as Date).valueOf(),
          beginDateString: getDateStringDMY(route.beginDate as Date),
          endDateString: getDateStringDMY(route.endDate as Date),
          WaysOfLivingUUID: settings?.wayOfLiving[route.wayOfLiving]?.id || null,
          WayToTravelUUID:
            this.oopt?.territories[route.pointIndex]?.routes[route.routeIndex]?.wayToTravelDTOList[route.wayToTravel]
              ?.id,
        };
      });

    const targetLocation: ITargetLocation[] =
      camps &&
      camps.map((camp: ICamp, index: number) => {
        return {
          objectIndex: index + 100,
          objectType: 'Parking',
          beginDate: moment(camp.beginDate as Date).valueOf(),
          endDate: moment(camp.endDate as Date).valueOf(),
          beginDateString: getDateStringDMY(camp.beginDate as Date),
          endDateString: getDateStringDMY(camp.endDate as Date),
          ParentRouteID: this.oopt.territories[camp.path[0]]?.routes[camp.path[1]]?.id,
          targetLocationUUID:
            this.oopt.territories[camp.path[0]]?.routes[camp.path[1]]?.targetLocationDTOList[camp.path[2]]?.id || null,
          countTents: camp.countTents,
        };
      });

    // WARNING - пока не удалять (закоменчено 28.07.2021)
    // if (!this.isGroup) {
    //   runInAction('setMainMember', () => {
    //     this.members = [this.members[0]];
    //   });
    // }

    const travelers =
      travelMembers &&
      travelMembers.map((member: Tourist, index: number) => {
        const memberData = {
          objectType: 'Traveler',
          isMainTraveler: index === 0,
          lastName: member.lastName,
          firstName: member.firstName,
          midName: member.midName,
          phone: member.phoneNumber ? member.phoneNumber.replace(/\D+/g, '') : null, // при отправке удаляем маску, сервер принимает только номер
          email: member.email,
          documentUUID: documentId(index) || '',
          docParam1: member.number1,
          docParam2: member.number2,
          docParam3: '_',
          privilegeCategoryUUID: privilegeId(index) || '',
          countryUUID: countryId(index) || '',
          city: member.city,
          cityUUID: member.cityUUID,
        };
        if (member.isEmployee) {
          memberData.privilegeCategoryUUID = privilegeForEmployee(member);
          memberData.documentUUID = documentId(0);
          memberData.countryUUID = countryId(0, member.countryUUID);
        }

        return memberData;
      });

    const trans =
      transport &&
      transport.map((vehicle: ITransport) => {
        return {
          objectIndex: vehicle.id,
          objectType: 'Transport',
          transportModel: vehicle.model || null,
          transportNumber: vehicle.number || null,
          transportType: vehicle.typeId || null,
          ParentRouteID: vehicle.routeId,
        };
      });

    let bodyMembers: any[];

    bodyMembers = [protectedTerritory, ...territory, ...travelers];

    if (isCampsRequired) {
      targetLocation.forEach(camp => bodyMembers.push(camp));
    }

    if (isTransportRequired) {
      trans.forEach(vehicle => bodyMembers.push(vehicle));
    }

    runInAction('setCollectedPostData', () => {
      this.setCollectedPostData(bodyMembers);
    });

    runInAction('isAllDataCollected', () => {
      this.isAllDataCollected = true;
      this.confirmationCodeRes = false;
      this.confirmationEmailRes = false;
      this.confirmation = {};
      this.isPostOrderLoading = true;
    });

    const response = await addTravelerRequest({ jsonFullData: JSON.stringify(bodyMembers) }, sessionId)
      .then(({ error, data }) => {
        if (!error) {
          runInAction('setResultToSession', () => {
            this.session = { ...data };
            this.isPostOrderLoading = false;
          });
          return data;
        }
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(`add Traveler Request: Error`, err);
      });

    this.isPostOrderLoading = false;

    return response;
  };

  @action
  setCollectedPostData = (data: any[]) => {
    this.collectedPostData = data;
  };

  @action
  setOOPT = (oopt: IOopt) => {
    // выбор парка
    if (isLogging) {
      // eslint-disable-next-line
      console.log('::: API v2.01 ::: SET OOPT :: ', oopt);
    }

    if (oopt) {
      runInAction(() => {
        this.oopt = oopt;
      });
      this.currentBackground = oopt.backgroundId as string;
    }
  };

  @action
  setPrevOOPTName = async () => {
    runInAction(() => {
      this.prevOoptId = this.oopt['id'];
    });
    return this.oopt['id'];
  };

  @action.bound setGroup(isGroup: any) {
    // флаг групповой заявки
    runInAction(() => {
      if (isLogging) {
        // eslint-disable-next-line
        console.log('SET GROUP EV', isGroup);
      }

      this.isGroup = isGroup === 'group';
    });
  }

  @action
  addMember = (args: { member?: Tourist; createdIn: CreatedIn }) => {
    // добавление формы для групповой заявки
    this.isGroup = true;
    const newMember = {};
    const { member, createdIn } = args;

    initMemberKeys.forEach(key => {
      newMember[key] = '';
    });

    if (member) {
      this.members = [...this.members, member];
    } else {
      this.members = [
        ...this.members,
        {
          id: getUUID(),
          lastName: '',
          firstName: '',
          midName: '',
          number1: '',
          number2: '',
          noMidName: false,
          mainMember: false,
          cityUUID: '',
          isPrivilegeModalVisible: false,
          isEmployee: false,
          createdIn,
          ...newMember,
        },
      ];
    }
  };

  @action
  appendMember = (member: Tourist, index: number) => {
    this.members[index] = Object.assign({}, member);
  };

  @action
  setPrivilegeModalVisible = (touristIndex: number, isVisible: boolean) => {
    this.members[touristIndex].isPrivilegeModalVisible = isVisible;
  };

  @action
  addMembersFromPa = (people: Tourist[]) => {
    //добавление людей из личного кабинета
    this.deleteAll();
    people.forEach(person => {
      person.firstName = person.firstName && person.firstName.charAt(0) + person.firstName.slice(1).toLowerCase();
      person.midName = person.midName && person.midName.charAt(0) + person.midName.slice(1).toLowerCase();
      person.lastName = person.lastName && person.lastName.charAt(0) + person.lastName.slice(1).toLowerCase();
    });
    if (people.length > 1) {
      this.isGroup = true;
      this.members = people;
    }
    if (people.length === 1) {
      this.isGroup = false;
      this.members = people;
    }
  };

  @action
  deleteMember = (member: Tourist) => {
    // удаление
    const index = lodashFindIndex(this.members, (item: any) => {
      return item.id === member.id
    })
    if (index !== -1) {
      this.members.splice(index, 1)
    }
    // runInAction(() => {
    //   const deletedMemberIndex = this.members.findIndex(item => item === member);
    //
    //   if (deletedMemberIndex > 0) {
    //     this.members.splice(deletedMemberIndex, 1);
    //   }
    // });
  };

  @action
  deleteAll = (createdIn?: CreatedIn) => {
    // сброс всех данных из формы личных данных
    if (isLogging) {
      // eslint-disable-next-line
      console.log('Clear all tourists');
    }
    runInAction(() => {
      if (createdIn) {
        this.members = this.members.filter((member: Tourist) => {
          return member.createdIn !== createdIn;
        });
        this.addMember({
          member: { ...individual, createdIn },
          createdIn,
        });
      } else {
        this.members = defaultMembers;
      }
      this.isGroup = false;
    });
  };

  @action
  setMemberProp = (memberId: string, key: string, value?: string) => {
    const memberIndex = this.members.findIndex((item: Tourist) => item.id === memberId);
    // данные пользователя из формы
    this.members[memberIndex] = {
      ...this.members[memberIndex],
      [key]: value,
    };
  };

  @action
  loadCities = async (query: string): Promise<any> => {
    // поиск города
    this.setIsCitiesLoading(true);

    let dataValues: City[] = [];

    await getPossibleCities(query)
      .then(({ error, data }) => {
        if (!error) {
          runInAction(() => {
            if (isLogging) {
              // eslint-disable-next-line
              console.log(':::: Find LOAD Cities', data); //dev
            }
            this.setCities(data);
            this.setIsCitiesLoading(false);
          });
        }

        dataValues = Object.values(data);
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(`ERROR Load cities.`, err);
      });

    return dataValues;
  };

  // Main Email
  @action
  setEmailMain = (args: { mEmail?: string; createdIn: CreatedIn }): void => {
    // установка главного мэйла, на который оформляется заявка
    const { mEmail, createdIn } = args;
    const membersCreated = this.members.filter((member: Tourist) => member.createdIn === createdIn);
    this.emailMain = mEmail !== undefined ? mEmail : membersCreated[0]['email'];
    this.confirmationEmailRes = {};
  };

  @action
  getPaymentUrl = async (locale: string): Promise<IServerResponse | void> => {
    if (this.emailMain) this.isPaymentUrlLoading = true;

    return (
      getPaymentURL(this.session.sessionId, this.session.travelerRequestId, locale)
        .then(({ data, error }) => {
          if (error) {
            this.isPaymentUrlLoading = false;
            throw data;
          } else {
            runInAction('getPaymentUrl', () => (this.resultGetPaymentUrl = data));

            return data;
          }
        })
        // eslint-disable-next-line
        .catch(e => console.error(e))
    );
  };

  @action
  setConfirmationEmailRes = (success: boolean, message: string | undefined) => {
    this.confirmationEmailRes = {
      success: success,
      replyMessage: message,
    };
  };

  @action
  getOrdersByMail = async (email: string): Promise<void> => {
    // запрос всех активных (не истекших) заявок на мэйл
    return getAllActiveTravelsRequests(email)
      .then(({ error, data }) => {
        if (!error && data) {
          runInAction(() => {
            this.session = data;
          });
        }

        return data;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(
          `ERROR Load - 
        Something wrong with post known email get Orders by Mail.`,
          err,
        );
      });
  };

  @action
  setAllTerrCoords = () => {
    // экстрактор из справочных координат территорий, реформат ? артефакт
    let allPoints: {} = {};

    lodashGet(this, ['oopt', 'territories'], []).forEach((terra: any, terraIndex: number) => {
      allPoints = {
        ...allPoints,
        [terra['id']]: [],
      };
      (lodashGet(terra, 'routes', []) as Array<any>).forEach((rou: any, iR: number) => {
        allPoints[terra.id].push({
          id: rou.id,
          name: rou.name,
          pointIndex: terraIndex,
          routeIndex: iR,
          coordinates: [],
          description: rou.description,
          color: rou.color,
          photos: rou.photos,
        });

        let splitted = (lodashGet(rou, 'coordinates', '105.3,52.0 105.3,52.0') as string).split(' ');

        splitted.forEach((c: string) => {
          const c2 = c.split(',');

          if (c2[0] && c2[1]) {
            allPoints[terra['id']][iR].coordinates.push({
              lng: +c2[0],
              lat: +c2[1],
            });
          }
        });
      });
    });

    runInAction(() => {
      this.pointsTerrRou7 = allPoints;
    });
  };

  @action
  getAllGeoCamps = () => {
    // экстракт из справочных и реформат координат всех стоянок для гугл-карты
    let campsByRoutes: {} = {};

    (lodashGet(this.oopt, 'territories', []) as []).forEach((t: any, tI: number) =>
      (lodashGet(t, 'routes', []) as []).forEach((r: any, rI: number) => {
        campsByRoutes = {
          ...campsByRoutes,
          [r.id]: [],
        };

        (lodashGet(r, 'targetLocationDTOList', []) as []).forEach((l: any, lI: number) => {
          if (l.geoN && l.geoE) {
            const lat = !+l.geoN ? +l.geoN.trim().split(',').join('.') : +l.geoN;
            const lng = !+l.geoE ? +l.geoE.trim().split(',').join('.') : +l.geoE;

            if (lat && lng) {
              campsByRoutes[r.id].push({
                lat: lat,
                lng: lng,
                path: [tI, rI, lI],
                pathId: [t.id, r.id, l.id],
                name: l.name,
                id: l.id,
                cordon: l.cordon,
              });
            }
          }
        });
      }),
    );

    runInAction(() => {
      if (isLogging) {
        // eslint-disable-next-line
        console.log(' : : ALL CAMPs GEO : : ', campsByRoutes);
      }
      this.campsGeoData = campsByRoutes;
    });
  };

  @action
  setRules = (createdIn: CreatedIn, checked: boolean) => {
    this.rules[createdIn] = checked;
  };

  @action
  setAllRules = (createdIn: CreatedIn, checked: boolean) => {
    this.allRules[createdIn] = checked;
  };

  @action
  setPolicy = (createdIn: CreatedIn, checked: boolean) => {
    this.policy[createdIn] = checked;
  };

  @action
  setPersonalData = (createdIn: CreatedIn, checked: boolean) => {
    this.personalData[createdIn] = checked;
  };

  @action
  setCommentValidity = (commentValidationStatus: boolean) => {
    this.isCommentValid = commentValidationStatus;
  };

  @action
  setDocCondition = (touristIndex: number, conditions: { srLength: number; nrLength: number }): void => {
    this.docCondition = {
      ...this.docCondition,
      [touristIndex]: conditions,
    };
  };

  @action
  setConfirmation = (confirmation: IServerResponse) => {
    this.confirmation = confirmation;
    this.confirmationCodeRes = confirmation;
  };

  @action
  setDefault = () => {
    if (isLogging) {
      // eslint-disable-next-line
      console.log('OrderDataStore to Default');
    }
    runInAction(() => {
      this.oopt = { ...initOopt };
      this.pointsTerrRou7 = {};
      this.isGroup = false;
      this.members = defaultMembers;
      this.session = { sessionId: '', travelerRequestId: '' };
      this.emailMain = '';
      this.cities = [
        {
          cityUUID: '',
          fullName: '',
        },
      ];
      this.confirmation = undefined;
      this.confirmationCodeRes = {};
      this.confirmationEmailRes = {};
      this.isAllDataCollected = false;
      this.rulesLink = undefined;
      this.rules = {
        companyAccount: false,
        mainService: false,
      };
      this.policy = {
        companyAccount: false,
        mainService: false,
      };
      this.personalData = {
        companyAccount: false,
        mainService: false,
      };
      this.visitCount = 0;
    });
  };
}

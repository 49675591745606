import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import React, { useEffect, Suspense, useState } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import { toJS } from 'mobx';
import { useAppStore } from '@store';
import { observer } from 'mobx-react';
import { RouteItem, SubMenu } from '@cuba-platform/react';

import menuItems from '@configuration/routing';
import { SERVER_HOST, isDevChecker, PROCESS_ENV } from '@configuration/config';
import { initGA } from '@configuration/analytics/googleAnalytics/googleAnalytics';

import { Header, HelmetGlobal, YandexMetrika } from '@components/common';

import cn from 'classnames';
import styles from './App.module.scss';

function collectRouteItems(items: Array<RouteItem | SubMenu>): RouteItem[] {
  return items.reduce((acc, curr) => {
    if ((curr as SubMenu).items == null) {
      // Trip item
      acc.push(curr as RouteItem);
    } else {
      // Items from sub menu
      acc.push(...collectRouteItems((curr as SubMenu).items));
    }
    return acc;
  }, [] as Array<RouteItem>);
}

initGA(); // Google Analytics

const AppComponent: React.FC<any> = () => {
  const {
    directorateStore,
    orderDataStore: { currentBackground },
  } = useAppStore();

  const [currentBackgroundState, setCurrentBackground] = useState(
    `url(${SERVER_HOST}api/getFile?lang=ru&id=${currentBackground})`,
  );
  const [startAnimation, setStartAnimation] = useState(true);

  useEffect(() => {
    directorateStore.loadDirectorates();
    setStartAnimation(false);
  }, []);

  const { directorates } = toJS(directorateStore); // directorates data
  const currentDirectorare = directorates[0]; // HARDCODE - only one directorate (no directorate id)
  const yaMetrikaId = Number(currentDirectorare?.yandexMetricId);

  useEffect(() => {
    setStartAnimation(true);

    setTimeout(() => {
      setCurrentBackground(`url(${SERVER_HOST}api/getFile?lang=ru&id=${currentBackground})`);
    }, 300);

    setTimeout(() => {
      setStartAnimation(false);
    }, 600);
  }, [currentBackground]);

  return (
    <>
      {/* Global app meta-data tags (SPA React --> Express.js --> static html) */}
      <HelmetGlobal currentDirectorare={currentDirectorare} />

      <div>
        {/* Yandex-metrika component */}
        {PROCESS_ENV === 'production' && <YandexMetrika yaMetrikaId={yaMetrikaId} />}

        <div
          style={{ backgroundImage: currentBackgroundState }}
          className={cn(
            styles['App'],
            isMobile ? 'isMobile' : '',
            startAnimation ? styles['App--start-animation'] : null,
          )}
        >
          <div className={styles['App__content']}>
            <Header />
            <HashRouter>
              <Suspense fallback={<span>Loading...</span>}>
                <Switch>
                  <Redirect exact from="/" to={isDevChecker.is ? '/welcome' : '/oopt'} />

                  {collectRouteItems(menuItems).map(route => (
                    <Route exact key={route.caption} path={route.pathPattern} component={route.component} />
                  ))}
                </Switch>
              </Suspense>
            </HashRouter>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(observer(AppComponent));

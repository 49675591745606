export const maskInput = (mask: any, input: any) => {
  if (!input || !input.length) {
    return '';
  }
  const ml = mask.length;
  let mp = 0;
  const pl = input.length;
  let pp = 0;
  const ret = [];
  while (mp < ml && pp < pl) {
    const isMask = mask[mp] === '#';
    const sameAsMask = !isMask && mask[mp] === input[pp];
    ret.push(isMask ? input[pp] : mask[mp]);
    if (isMask || sameAsMask) {
      pp++;
    }
    mp++;
  }
  return ret.join('');
};

export const getOptionLabel = ({ options, value }: { options: { label: string; value: any }[]; value: any }) => {
  const option = options.find((item: { label: string; value: any }) => item.value === value);
  const label = option ? option.label : undefined;

  return label;
};

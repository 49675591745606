import { create } from 'mobx-persist';
// import { enableLogging} from "mobx-logger";

import { isLogging, version } from '@configuration/config';
//stores
import AppSettingsStore from './AppSettingsStore';
import OrderDataStore from './OrderDataStore';
import TripStore from './TripStore';
import MapStore from './MapStore';
import SettingsStore from './SettingsStore';
import SelectedUserTerritoryStore from './SelectedUserTerritoryStore';
import UserStore from './UserStore';
import TouristStore from './TouristStore';
import CompanyRegistrationStore from './CompanyRegistrationStore';
import CompanyAccountStore from './CompanyAccountStore';
import PagesStore from './PagesStore';
import DirectorateStore from './DirectorateStore';

const hydrate = create({});
const persistenceKey = version.split('.').join('-');

// снимать комментарии когда нужны консоль логи
// enableLogging({
//     action: true,
//     compute: true,
//     reaction: true,
//     transaction: true
// })

export default class AppStore {
  appSettingsStore = new AppSettingsStore(); // общие справочные данные

  orderDataStore = new OrderDataStore(); // заявка и данные пользователя

  tripStore = new TripStore(); // маршрут в заявке

  mapStore = new MapStore(); // карта

  //refactored - в конечном варианте рест, декомпозировать на более узкопредметные сторы
  settingsStore = new SettingsStore();

  selectedUserTerritoryStore = new SelectedUserTerritoryStore(); //Настройки выбранной территории, для отображения правил и тарифов

  userStore = new UserStore();

  touristStore = new TouristStore();

  companyRegistrationStore = new CompanyRegistrationStore();

  companyAccountStore = new CompanyAccountStore();

  pagesStore = new PagesStore();

  directorateStore = new DirectorateStore(); // список дирекций

  constructor() {
    // подкл в локалсторадж

    hydrate(`appSettings${persistenceKey}`, this.appSettingsStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('AppSettingsStore has been hydrated'),
    );
    hydrate(`orderData${persistenceKey}`, this.orderDataStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('OrderDataStore has been hydrated'),
    );
    hydrate(`tripStore${persistenceKey}`, this.tripStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('TripStore has been hydrated'),
    );
    hydrate(`mapStore${persistenceKey}`, this.mapStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('MapStore has been hydrated'),
    );
    hydrate(`companyAccountStore${persistenceKey}`, this.companyAccountStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('companyAccountStore has been hydrated'),
    );
    hydrate(`userStore${persistenceKey}`, this.userStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('userStore has been hydrated'),
    );
    // refactor store
    hydrate(`settingsStore${persistenceKey}`, this.settingsStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('SettingsStore has been hydrated'),
    );
    hydrate(`selectedUserTerritoryStore${persistenceKey}`, this.selectedUserTerritoryStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('SelectedUserTerritoryStore has been hydrated'),
    );
    hydrate(`selectedDirectorateStore${persistenceKey}`, this.directorateStore).then(
      // eslint-disable-next-line
      () => isLogging && console.log('selectedDirectorateStore has been hydrated'),
    );
  }

  /*setMainStore(mainStore: MainStore) { // кубовские
    this.mainStore = mainStore;
  }*/

  /*@action
  initialize(): void {
    console.log('INITIALIZE AppSettStore')
    this.appSettingsStore
      .load()
  }*/
}

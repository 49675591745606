import { action, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';

import { isLogging } from '@configuration/config';
import { LayoutUtils } from '@utils';
import { MapService } from '@services';

const { getForbiddenZones, getOtherMapObjects, getTargetLocationsGeoData } = MapService;

const { isMobileDevice } = LayoutUtils;

export default class MapStore {
  @observable zoom?: number = 8;

  @observable center?: { lat: number; lng: number } = undefined;

  @persist('object') @observable park?: {} = undefined; //пока нет - текущий парк

  @persist @observable parkId?: string = undefined;

  @persist('list') @observable territories?: any[] = undefined; //пока нет справочные

  @persist('list') @observable routes?: any[] = undefined; //пока нет

  @persist('list') @observable camps?: any[] = undefined; //пока нет

  @observable.deep trails?: any[] = []; //тропы

  @persist('list') @observable selectedTerritoriesId?: any[] = undefined; //пока нет - добавленные в маршрут

  @persist('list') @observable selectedRoutes?: any[] = undefined; //пока нет

  @persist('list') @observable selectedCamps?: any[] = undefined; //пока нет

  @observable currentActiveTerritoryId: string = ''; // active = в фокусе

  @observable currentActiveRouteId?: string = undefined;

  @observable currentActiveCamp?: {} = undefined;

  @observable hoveredTerritoryId: string = ''; // подсвеченные

  @observable hoveredRouteId?: string = undefined;

  @observable hoveredCamp: {} | undefined = undefined;

  @persist('list') @observable ptGeoPolygon?: any[] = undefined; // кластеры

  @persist('list') @observable forbiddenGeoPolygon?: any[] = undefined; // заповедники

  @persist('object') @observable otherMapObjects:
    | {
        informationCenterDTOS: [];
        forestryDTOS: [];
      }
    | undefined = undefined; // заповедники

  @persist('list') @observable targetLocationsGeoData?: any[] = undefined; // стоянки с тропами

  @observable isPTPolygonLoading: boolean = false;

  @observable isForbiddenPolygonLoading: boolean = false;

  @action
  setIsPTPolygonLoading = (isPTPolygonLoading: boolean) => {
    this.isPTPolygonLoading = isPTPolygonLoading;
  };

  @action
  setPtGeoPolygon = (ptGeoPolygon: any[]) => {
    this.ptGeoPolygon = ptGeoPolygon;
  };

  @action loadForbiddenGeoPolygon = (id: string): void => {
    // загрузка и обработка заповедных зон
    if (isMobileDevice) {
      // eslint-disable-next-line
      console.log('Mobile device detected. ForbiddenGeoPolygon off');
    } else {
      this.isForbiddenPolygonLoading = true;
      try {
        getForbiddenZones(id)
          .then(({ error, data }) => {
            if (!error) {
              if (data && data.replyMessage && JSON.parse(data.replyMessage)) {
                runInAction(() => {
                  this.forbiddenGeoPolygon = JSON.parse(data.replyMessage) || [];
                });
              }
            }

            this.isForbiddenPolygonLoading = false;
          })
          .catch(err => {
            // eslint-disable-next-line
            console.error(`ERROR settings POLYGONS`, err);
          });
      } catch (err) {
        // eslint-disable-next-line
        console.error('Some wrong fetch POLYGONS', err);
      }
    }
  };

  @action loadOtherMapObjects = ({ id, lang }: { id: string; lang?: string }): void => {
    if (isMobileDevice) {
      // eslint-disable-next-line
      console.log('Mobile device detected. loadOtherMapObjects off');
    } else {
      try {
        getOtherMapObjects(lang, id)
          .then(({ error, data }) => {
            if (!error) {
              if (data && data.replyMessage && JSON.parse(data.replyMessage)) {
                runInAction(() => {
                  this.otherMapObjects = JSON.parse(data.replyMessage) || [];
                });
              }
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.error(`ERROR settings POLYGONS`, err);
          });
      } catch (err) {
        // eslint-disable-next-line
        console.error('Some wrong fetch POLYGONS', err);
      }
    }
  };

  @action loadTargetLocationsGeoData = ({ id, lang }: { id: string; lang?: string }): void => {
    if (isMobileDevice) {
      // eslint-disable-next-line
      console.log('Mobile device detected. loadOtherMapObjects off');
    } else {
      try {
        getTargetLocationsGeoData(lang, id)
          .then(({ error, data }) => {
            if (!error) {
              if (data && data.replyMessage && JSON.parse(data.replyMessage)) {
                runInAction(() => {
                  this.targetLocationsGeoData = JSON.parse(data.replyMessage) || [];
                });
              }
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.error(`ERROR settings Target Locations`, err);
          });
      } catch (err) {
        // eslint-disable-next-line
        console.error('Some wrong fetch Target Locations', err);
      }
    }
  };

  @action setZoom = (zoom: number) => {
    // установка зума карты
    runInAction(() => {
      this.zoom = zoom;
    });
  };

  @action
  setCenter = (center: { lat: number; lng: number }) => {
    if (center.lat && center.lng) {
      this.center = {
        lat: +center.lat,
        lng: +center.lng,
      };
    } else {
      this.center = undefined;
      this.zoom = undefined;
    }
  };

  @action setParkId = (parkId: string) => {
    //  id текущего парка (ООПТ)
    if (isLogging) {
      // eslint-disable-next-line
      console.log('MAP PARK ID', parkId);
    }

    runInAction(() => {
      this.parkId = parkId;
    });
  };

  /*@action setPark = (park: any) => {
    runInAction(() => {});
  }

  @action getTerritories = (terraId: string) => {
    runInAction(() => {});
  }

  @action getRoutes = (rouId: string) => {
    runInAction(() => {});
  }

  @action getCamps = (camp: {}) => {
    runInAction(() => {});
  }

  @action setSelectedTerritory = (terraId: string) => {
    runInAction(() => {});
  }

  @action setSelectedRoute = (rouId: string) => {
    runInAction(() => {});
  }

  @action setSelectedCamp = (camp: {}) => {
    runInAction(() => {});
  }*/

  @action resetTrails = () => {
    runInAction(() => {
      this.trails = [];
    });
  };

  @action setTrails = (campsIds: any[]) => {
    this.resetTrails();
    if (campsIds && campsIds.length && this.targetLocationsGeoData && this.targetLocationsGeoData.length) {
      const filterById = this.targetLocationsGeoData.filter((c: any) => campsIds.includes(c && c.id));
      runInAction(() => {
        this.trails = filterById;
      });
    }
  };

  @action setActiveTerritoryId = (terraId: string) => {
    // id территории, на которой фокус
    this.currentActiveTerritoryId = terraId;
  };

  @action setActiveRouteId = (rouId: string) => {
    // id маршрута, на котором фокус
    this.currentActiveRouteId = rouId;
  };

  @action setActiveCamp = (camp: {}) => {
    // стоянка, на которой фокус
    if (isLogging) {
      // eslint-disable-next-line
      console.log('MAP SET ACTIVE CAMP {}', camp);
    }

    runInAction(() => {
      this.currentActiveCamp = camp;
    });
  };

  @action setHoveredTerritoryId = (terraId: string) => {
    // подсвеченная территория, только с формы
    runInAction(() => {
      this.hoveredTerritoryId = terraId;
    });
  };

  @action setHoveredRouteId = (rouId: string) => {
    // подсвеченный маршрут , только с формы
    runInAction(() => {
      this.hoveredRouteId = rouId;
    });
  };

  @action
  setHoveredCamp = (camp?: {}) => {
    // подсвеченная стоянка , только с формы
    this.hoveredCamp = camp;
  };

  @action setDefault = () => {
    runInAction(() => {
      this.zoom = 8;
      this.center = undefined;

      this.park = undefined;
      this.parkId = undefined;

      this.territories = undefined;
      this.routes = undefined;
      this.camps = undefined;

      this.selectedTerritoriesId = undefined;
      this.selectedRoutes = undefined;
      this.selectedCamps = undefined;

      this.currentActiveTerritoryId = '';
      this.currentActiveRouteId = undefined;
      this.currentActiveCamp = undefined;

      this.hoveredTerritoryId = '';
      this.hoveredRouteId = undefined;
      this.hoveredCamp = undefined;
    });
  };
}

const accentColor = '39,143,52';

export const reactSelectCustomStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundClip: 'border-box',
    backgroundColor: '#fafafa',
    borderRadius: '0.25rem',

    // selects focus
    border: state.isFocused ? `1px solid rgba(${accentColor}, 0.75)` : '1px solid #ced4da',
    boxShadow: state.isFocused ? '0 0 0 5px 0 rgba(${accentColor}, 0.1)' : null,
    '&:hover': {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "blue" : "blue"
    },
  }),

  option: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isDisabled ? `rgba(0,0,0, 0.1)` : 'inherit',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: state.isSelected ? `rgba(${accentColor}, 0.1)` : null,
      '&:hover': {
        backgroundColor: `rgba(${accentColor}, 0.1)`,
      },
    }
  },
};

import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
    // загрузка границы парка
    getForbiddenZones: (id: string): Promise<HttpResponse> => {
        return httpClient.get(`${REST_CURRENT}getForbiddenZones`, { params: { id } }).then((response: HttpResponse) => {
            const { data, error } = response;

            if (error) {
                return { data: null, error };
            } else {
                return { data, error };
            }
        });
    },

    // загрузка других объектов карты
    getOtherMapObjects: (lang: string = ru, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getOtherMapObjects`, { params: { lang, id } })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // загрузка координат целей на карте
    getTargetLocationsGeoData: (lang: string = ru, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getTargetLocationsGeoData`, { params: { lang, id } })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },
};

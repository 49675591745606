import React from 'react';
import { Helmet } from 'react-helmet';

interface IHelmetProps {
  currentDirectorare: {
    description: string;
    keywords: string;
    name: string;
    appSite: string;
    posterImage: string;
  }
}

const HelmetGlobal: React.FC<IHelmetProps> = ({ currentDirectorare }) => {
  return (
    <Helmet>
      <meta name="language" content="RU" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-barstyle" content="black-translucent" />
      <meta name="url"            content={currentDirectorare?.appSite} />
      <meta name="keywords"       content={currentDirectorare?.keywords} />
      <meta name="description"    content={currentDirectorare?.description} />
      <meta name="identifier-URL" content={currentDirectorare?.appSite} />
      <meta name="image"          content={`${currentDirectorare?.appSite}/ausweiss/rest/api/getFile?lang=ru&id=${currentDirectorare?.posterImage}`} />

      {/* open graph */}
      <meta property="og:type"         content="website" />
      <meta property="og:url"          content={currentDirectorare?.appSite} />
      <meta property="og:title"        content={currentDirectorare?.name} />
      <meta property="og:description"  content={currentDirectorare?.description} />

      {/* need preview image */}
      <meta property="og:locale"       content="ru_RU"/>
      <meta property="og:image:type"   content="image/png" />
      <meta property="og:image:width"  content="1024" />
      <meta property="og:image:height" content="512" />
      <meta property="og:site_name"    content={currentDirectorare?.name} />
      <meta property="og:image"        content={`${currentDirectorare?.appSite}/ausweiss/rest/api/getFile?lang=ru&id=${currentDirectorare?.posterImage}`} />

      {/* google */}
      <meta itemProp="name" content={currentDirectorare?.name} />
      <meta itemProp="description" content={currentDirectorare?.description} />

      {/* need preview image */}
      <meta itemProp="image" content={`${currentDirectorare?.appSite}/ausweiss/rest/api/getFile?lang=ru&id=${currentDirectorare?.posterImage}`} />

      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={currentDirectorare?.appSite} />
      <meta name="twitter:site" content={currentDirectorare?.appSite} />
      <meta name="twitter:title" content={currentDirectorare?.name} />
      <meta name="twitter:description" content={currentDirectorare?.description} />
      <meta name="twitter:image:src" content={`${currentDirectorare?.appSite}/ausweiss/rest/api/getFile?lang=ru&id=${currentDirectorare?.posterImage}`} />

      {/* title */}
      <title>{`${currentDirectorare?.name}`}</title>
    </Helmet>
  )
} 

export default HelmetGlobal;

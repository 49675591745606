import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { SERVER_HOST } from '@configuration/config';
import { Burger } from '@components/common/Icons';
import Login from './partials/Login';
import { useAppStore } from '@store';

import styles from './CompanyAccountHeader.module.scss';

const CompanyAccountHeader: React.FC<any> = observer((props: any) => {
  const history = useHistory();

  const {
    userStore: { userName },
    companyAccountStore: { companySessionId, shortCompanyName, firmName, logoutCompany, setLoginCompanyData },
    directorateStore: { directorates },
  } = useAppStore();

  const currnetDirectorate = toJS(directorates)[0];
  const { isMenuOpen, onBurgerClick } = props;

  const onLogout = () => {
    logoutCompany(companySessionId).then((request: any) => {
      if (request && request.success) {
        setLoginCompanyData({});
        history.push('sign-in');
        return;
      }

      if (request && !request.success) {
        toast.error(`Данные отклонены сервером. Причина: ${request.replyMessage}`);
        return;
      }

      toast.error('Что то пошло не так. Попробуйте ещё раз.');
    });
  };

  return (
    <header className={styles['CompanyAccountHeader']}>
      <div className={styles['CompanyAccountHeader__burger']} onClick={onBurgerClick}>
        <Burger
          classNameProp={styles['CompanyAccountHeader__burger-svg']}
          fill={isMenuOpen ? 'rgba(255,255,255, 1)' : 'rgba(255,255,255, 0.5)'}
        />
      </div>

      <NavLink to="/company-account/workspace" className={styles['CompanyAccountHeader__logo']}>
        <img
          className={styles['CompanyAccountHeader__logo-img']}
          src={`${SERVER_HOST}api/getFile?lang=ru&id=${currnetDirectorate?.logoSiteTop}`}
          alt="headerLogo"
        />
      </NavLink>

      <Login userName={userName} companyName={shortCompanyName || firmName} onLogout={onLogout} />
    </header>
  );
});

export default CompanyAccountHeader;

import { action, observable, computed, runInAction } from 'mobx';
import { persist } from 'mobx-persist';

import { HttpResponse } from '@api';
import { LayoutUtils } from '@utils';
import { isLogging } from '@configuration/config';
import { IAppSettings } from '@typing/AppSettings';
import { ParkShortModel } from '@typing/ParkShortModel';
import { AppSettingsService } from '@services';

const { getOoptsShort, getAllAppSettings, getGeoData, getFile, getAlertForUsers } = AppSettingsService;

const criticalFlag = 'CRITICAL';
const { isMobileDevice } = LayoutUtils;

export default class AppSettingsStore {
  @persist('list') @observable ooptsShort?: ParkShortModel[] = [];

  @observable isOoptsShortLoading: boolean = false;

  @persist('object') @observable appSettings: IAppSettings | null = null;

  @observable isSettingsLoading: boolean = false;

  @persist('object') @observable polygons: {} = {};

  @persist('object') @observable polygonsCenters: {} = {};

  @observable isMapPolygonsLoading: boolean = false;

  @persist('list') transportIndexes?: number[] = [];

  @persist hostIndex?: number = undefined;

  @persist('object') @observable ooptsNames: {} = {};

  @observable alertMessage: string = '';

  @computed get alertMessageClean() {
    return this.alertMessage.replace(criticalFlag, '');
  }

  @computed get serviceNotAvailable() {
    return this.alertMessage.startsWith(criticalFlag);
  }

  @action
  setAlertMessage = (alertMessage: string) => {
    this.alertMessage = alertMessage;
  };

  @action
  setOoptsNames = (ooptsNames: {}) => {
    this.ooptsNames = ooptsNames;
  };

  @action
  setIsSettingsLoading = (isSettingsLoading: boolean) => {
    this.isSettingsLoading = isSettingsLoading;
  };

  @action
  setAppSettings = (appSettings: IAppSettings | null) => {
    this.appSettings = appSettings;
  };

  @action
  setOoptsShort = (ooptsShort: ParkShortModel[]) => {
    this.ooptsShort = [...ooptsShort];
  };

  @action
  setIsOoptsShortLoading = (isOoptsShortLoading: boolean) => {
    this.isOoptsShortLoading = isOoptsShortLoading;
  };

  @action loadOoptsShort = async (lang?: string) => {
    // загрузка короткого описания парков
    this.setIsOoptsShortLoading(true);

    return getOoptsShort(lang)
      .then(({ error, data }: HttpResponse) => {
        if (isLogging) {
          // eslint-disable-next-line
          console.log(`OOPT SHORT obj RES`, data);
        }

        const success = data ? true : false;

        if (!error) {
          runInAction(() => {
            this.setOoptsShort(data);
          });
        }

        this.setIsOoptsShortLoading(false);

        return success;
      })
      .catch((err: any) => {
        // eslint-disable-next-line
        console.error(`ERROR load oopts SHORT obj`, err);
      });
  };

  @action loadAllAppSettings = async (lang?: string): Promise<any> => {
    // загрузка большей части разнотипных данных (эндпоинт getAllOptions)
    this.isSettingsLoading = true;

    return getAllAppSettings(lang)
      .then(({ error, data }) => {
        let sett: IAppSettings = {
          pCategory: [],
          pTerritory: [],
          docType: [],
          wayOfLiving: [],
          wayToTravel: [],
          country: [],
          transportType: [],
        };

        if (!error) {
          data.forEach((item: any) => {
            const { objType } = item;
            if (sett[objType]) {
              sett[objType].push(item);
            }
          });

          let oopts: any[] = sett.pTerritory[0]['territories'];

          const normalizeOopt: any = {};

          oopts.forEach(item => {
            const { id, name } = item;

            normalizeOopt[id] = { name };
          });

          runInAction(() => {
            this.setOoptsNames(normalizeOopt);
          });
        }

        runInAction(() => {
          this.setAppSettings(sett);
          this.setIsSettingsLoading(false);
        });

        return sett;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(`ERROR settings AppSettingsStore`, err);
      });
  };

  @action loadPolygons = (ooptId: string): void => {
    // загрузка и обработка координат границ всех территорий парка
    if (isMobileDevice) {
      // console.log('Mobile device detected. GeoData POLYGONS off');
    } else {
      this.isMapPolygonsLoading = true;
      try {
        getGeoData(ooptId)
          .then(({ error, data }) => {
            // res is [{id: string, name: string, coordinates: string}]
            const pols: {} = {};

            if (!error) {
              //get polygons from server
              data.forEach((polygon: { id: string; name: string; color: string; coordinates: string }) => {
                pols[polygon['id']] = {
                  id: polygon['id'],
                  name: polygon.name,
                  color: polygon.color,
                  coordinates: [],
                };

                //2-t ; extract coordinates from polygons
                let polys = polygon.coordinates ? polygon.coordinates.split(';') : [];
                //simpler
                polys.forEach((poly: string, partI: number) => {
                  if (poly) {
                    pols[polygon['id']].coordinates.push([]);

                    poly
                      .trim()
                      .split(' ')
                      .forEach((xy: any, index: number) => {
                        let sp = xy.split(',');
                        if (sp[0] && sp[1]) {
                          pols[polygon['id']].coordinates[partI].push({
                            lng: +sp[0],
                            lat: +sp[1],
                          });
                        } else {
                          // eslint-disable-next-line
                          console.error('SOME ERROR: handle POLYGONS COORDINATES', index, xy);
                        }
                      });
                  }
                });
              });
              this.isMapPolygonsLoading = false;

              runInAction(() => {
                // pols = {id: {id: string, name: string, coordinates: string[]}}
                this.polygons = pols;
              });
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.error(`ERROR settings POLYGONS`, err);
          });
      } catch (err) {
        // eslint-disable-next-line
        console.error('Some wrong fetch POLYGONS', err);
        this.isMapPolygonsLoading = true;
      }
    }
  };

  @action loadFile = async (locale: string, id: string) => {
    // загрузка файла по id
    getFile(locale, id)
      .then(({ error, data }) => {
        if (!error) {
          return URL.createObjectURL(data);
        }
        return;
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(`ERROR loadParks`, err);
      });
  };

  @action loadAlertForUsers = async (locale: string) => {
    getAlertForUsers(locale).then(({ error, data }) => {
      if (!error) {
        this.setAlertMessage(data);
      }
    });
  };

  @action setDefault = () => {
    // сброс сторы на начальные значения
    runInAction(() => {
      this.ooptsShort = [];
      this.appSettings = null;
      this.polygons = {};
      this.transportIndexes = [];
      this.hostIndex = undefined;
      this.ooptsNames = [{ id: 'fakeOOPPT', name: undefined, name2: undefined }];
    });
  };
}

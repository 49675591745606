import React, { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';
import styles from './ButtonCustom.module.scss';

type TProps = {
  href?: string;
  tabIndex?: number;
  disabled?: boolean;
  type?: 'primary' | 'primary-outlined' | 'primary-big' | 'secondary' | 'secondary-big';
  buttonType?: 'button' | 'reset' | 'submit' | undefined;
  onClick?: MouseEventHandler;
  children: any;
}

const ButtonCustom: React.FC<TProps> = ({
  href = '',
  tabIndex,
  disabled = false,
  type = 'primary',
  buttonType = 'button',
  onClick,
  children,
}) => {
  // link
  if (href) {
    return (
      <NavLink 
        to={href} 
        tabIndex={tabIndex}
        style={{
          opacity: disabled ? '0.5' : '1',
          cursor: disabled ? 'default' : 'pointer',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
        className={cn(styles['ButtonCustom'], styles[`ButtonCustom__${type}`])}
      >
        <span>{children}</span>
      </NavLink>
    )

  // button
  } else {
    return (
      <button
        tabIndex={tabIndex}
        type={buttonType}
        onClick={onClick}
        disabled={disabled}
        className={cn(styles['ButtonCustom'], styles[`ButtonCustom__${type}`])}
      >
        <span>{children}</span>
      </button>
    );
  }
};

export default ButtonCustom;

import React from 'react';

interface IProps {
  classNameProp?: string;
}

const Orders: React.FC<IProps> = ({ classNameProp }) => {
  return (
    <svg
      id='Layer_1'
      enableBackground='new 0 0 512.364 512.364'
      height='512'
      viewBox='0 0 512.364 512.364'
      width='512'
      className={classNameProp}
    >
      <g>
        <path d='m83.767 209.028c4.952 4.63 15.48 5.638 21.349-1.24l37.573-42.868c5.458-6.226 4.829-15.693-1.404-21.145-6.232-5.451-15.709-4.825-21.166 1.402l-27.53 31.409-7.379-6.67c-6.144-5.552-15.628-5.079-21.187 1.058-5.558 6.137-5.084 15.612 1.059 21.165z' />
        <path d='m268.339 182.345h-86.551c-8.284 0-15 6.709-15 14.985s6.716 14.985 15 14.985h86.551c8.284 0 15-6.709 15-14.985s-6.716-14.985-15-14.985z' />
        <path d='m268.339 244.501h-194.425c-8.284 0-15 6.709-15 14.985s6.716 14.985 15 14.985h194.425c8.284 0 15-6.709 15-14.985s-6.716-14.985-15-14.985z' />
        <path d='m83.767 380.808c4.952 4.63 15.48 5.638 21.349-1.24l37.573-42.868c5.458-6.226 4.829-15.693-1.404-21.145-6.232-5.451-15.709-4.825-21.166 1.402l-27.53 31.409-7.379-6.669c-6.144-5.552-15.629-5.078-21.187 1.058-5.558 6.137-5.084 15.613 1.059 21.165z' />
        <path d='m268.339 354.07h-86.551c-8.284 0-15 6.709-15 14.985s6.716 14.985 15 14.985h86.551c8.284 0 15-6.709 15-14.985s-6.716-14.985-15-14.985z' />
        <path d='m268.339 415.337h-194.425c-8.284 0-15 6.709-15 14.985s6.716 14.985 15 14.985h194.425c8.284 0 15-6.709 15-14.985s-6.716-14.985-15-14.985z' />
        <path d='m475.52 0h-285.617c-2.718.013-5.967.548-9.176 1.796-3.034-.582-6.143-.884-9.297-.884-12.868 0-25.012 4.959-34.193 13.964-5.543 5.436-9.632 11.979-12.055 19.137h-16.032c-11.322 0-20.594 8.832-21.333 19.959h-52.898c-19.116 0-34.668 15.536-34.668 34.633v389.127c0 19.097 15.552 34.633 34.668 34.633h273.023c15.992 0 29.483-10.876 33.468-25.611h136.63c18.027-1.093 34.407-17.459 34.069-36.551v-413.652c0-20.154-16.414-36.551-36.589-36.551zm-357.776 63.982h19.878c8.167 0 14.833-6.527 14.997-14.684.204-10.156 8.643-18.418 18.811-18.418s18.607 8.262 18.812 18.418c.164 8.157 6.83 14.684 14.997 14.684h19.878v16.505h-107.373zm194.866 413.749c0 2.572-2.094 4.664-4.668 4.664h-273.023c-2.574 0-4.668-2.092-4.668-4.664v-389.127c0-2.571 2.094-4.663 4.668-4.663h52.825v3.184c0 12.865 10.477 23.332 23.355 23.332h120.663c12.878 0 23.355-10.466 23.355-23.332v-3.184h52.825c2.574 0 4.668 2.092 4.668 4.663zm169.499-27.529c0 3.342-2.5 6.148-5.816 6.527-.144.017-.288.035-.431.056h-133.252v-368.181c0-19.096-15.552-34.633-34.668-34.633h-52.907c-.831-11.141-10.15-19.959-21.512-19.959h-15.845c-.465-1.374-1.011-2.718-1.597-4.043h259.439c3.633 0 6.589 2.953 6.589 6.582z' />
      </g>
    </svg>
  );
};

export default Orders;

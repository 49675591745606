import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { injectIntl } from 'react-intl';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { getMainStore } from '@cuba-platform/react';

import { SERVER_HOST } from '@configuration/config';
import { LanguageSwitcher } from '@components/common';
import { Orders, Enter, Mail, Phone, Vkontakte, Facebook, Instagram } from '@components/common/Icons';
import { useAppStore } from '@store';

import cn from 'classnames';
import styles from './Header.module.scss';

const Header = (props: any) => {
  const { directorateStore } = useAppStore();
  const { intl } = props;
  const { directorates } = toJS(directorateStore); // directorates data
  const currnetDirectorate = directorates[0]; // HARDCODE - only one directorate (no directorate id)
  const socialLinks = currnetDirectorate?.socialNetworksLinks;
  const localeValue = getMainStore().locale;

  useEffect(() => {
    directorateStore.loadDirectorates();
  }, []);

  return (
    <header className={styles['Header']}>
      {/* header top */}
      <div className={styles['Header__top']}>
        <div className={styles['Header__contacts']}>
          {currnetDirectorate?.email ? (
            <>
              <span className={cn(styles['Header__top-span'], styles['Header__contacts-span'])}>
                {intl.formatMessage({ id: 'header.service' })}:&nbsp;
              </span>

              {/* mail */}
              <a
                className={cn(styles['Header__top-link'], styles['Header__contacts-link'])}
                href={`mailto:${currnetDirectorate?.email}`}
              >
                <Mail classNameProp={styles['Header__contacts-icon']} />
                {currnetDirectorate?.email}
              </a>

              {/* phone - take 0 index of contactPhone array */}
              <a
                className={cn(styles['Header__top-link'], styles['Header__contacts-link'])}
                href={`tel:${currnetDirectorate?.contactPhone[0]}`}
              >
                <Phone classNameProp={styles['Header__contacts-icon']} />
                {currnetDirectorate?.contactPhone[0]}
              </a>
            </>
          ) : null}
        </div>

        <div className={styles['Header__social']}>
          {socialLinks?.length > 0 ? (
            <>
              <span className={styles['Header__social-span']}>
                {intl.formatMessage({ id: 'header.social' })}:&nbsp;
              </span>
              {socialLinks.map((link: string) => {
                return (
                  <a
                    className={styles['Header__social-link']}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    key={`${link}-id`}
                  >
                    {link.includes('vk.com') && <Vkontakte classNameProp={styles['Header__social-icon']} />}
                    {link.includes('facebook.com') && <Facebook classNameProp={styles['Header__social-icon']} />}
                    {link.includes('instagram.com') && <Instagram classNameProp={styles['Header__social-icon']} />}
                  </a>
                );
              })}
            </>
          ) : null}
        </div>
      </div>

      <Navbar bg="dark" expand="md" variant="dark" className={styles['Header__navbar']}>
        <Container fluid>
          {/* logo */}
          <Navbar.Brand href="#/" className={styles['Header__brand']}>
            <img
              src={`${SERVER_HOST}api/getFile?lang=ru&id=${currnetDirectorate?.logoSiteTop}`}
              alt="headerLogo"
              className={styles['Header__logo']}
            />
          </Navbar.Brand>

          {/* burger */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles['Header__burger']} />

          {/* links */}
          <Navbar.Collapse id="basic-navbar-nav" className={styles['Header__links']}>
            {/* Мои заявки */}
            <Nav.Item>
              <Nav.Link href="#/orders/">
                <Orders classNameProp={styles['Header__links-icon']} />
                {intl.formatMessage({ id: 'header.myOrders' })}
              </Nav.Link>
            </Nav.Item>

            {/* Войти в ЛК */}
            {localeValue === 'ru' && (
              <Nav.Item>
                <Nav.Link href="#/company-account">
                  <Enter classNameProp={styles['Header__links-icon']} />
                  {intl.formatMessage({ id: 'header.signIn' })}
                </Nav.Link>
              </Nav.Item>
            )}
          </Navbar.Collapse>

          {/* langs switcher */}
          <div className={styles['Header__langs']}>
            <LanguageSwitcher />
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default injectIntl(observer(Header));

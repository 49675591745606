import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useRouteMatch, NavLink, Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { Layout, Menu, Spin } from 'antd';

import { ROUTES } from '@constants';
import { useAppStore } from '@store';
import CompanyAccountHeader from './partials/CompanyAccountHeader/CompanyAccountHeader';

// from partials
// const About      = lazy(() => import('./partials/About/About'));
const Users = lazy(() => import('./partials/Users/Users'));
const AllRequest = lazy(() => import('./partials/AllRequests/AllRequests'));
const Employees = lazy(() => import('./partials/Employees/Employees'));
const WorkSpace = lazy(() => import('./partials/WorkSpace/WorkSpace'));

// from components
const TouristsData = lazy(() => import('@pages/TouristsDataScreen/TouristsDataScreen'));
const TourData = lazy(() => import('@pages/TourDataScreen/TourDataScreen'));
const TranzitData = lazy(() => import('@pages/TourDataScreen/partials/TranzitDataScreen/TranzitDataScreen'));
const Payment = lazy(() => import('@pages/PaymentScreen/PaymentScreen'));
const TranzitPayment = lazy(() => import('@pages/TranzitPaymentScreen/TranzitPaymentScreen'));
const PaymentFailScreen = lazy(() => import('@pages/PaymentFailScreen/PaymentFailScreen'));
const PaymentSuccess = lazy(() => import('@pages/PaymentSuccessScreen/PaymentSuccessScreen'));
const OrdersPage = lazy(() => import('@pages/OrdersPage/OrdersPage'));
const OrderInfoPage = lazy(() => import('@pages/OrderInfoPage/OrderInfoPage'));
const PersonalAccountAuthPage = lazy(() => import('@pages/PersonalAccountAuthPage/PersonalAccountAuthPage'));

import cn from 'classnames';
import styles from './CompanyAccount.module.scss';
import { observer } from 'mobx-react';
import { getMainStore } from "@cuba-platform/react";

const { ROUTE_COMPANY_ACCOUNT } = ROUTES;

const { Sider, Content } = Layout;

const CompanyAccount = () => {
  const match = useRouteMatch();

  const {
    companyAccountStore: { loadCompanyEmployeesTypes, getCompanySessionExpired },
  } = useAppStore();

  const history = useHistory();

  const routeUsers = '/users';
  const routeAllRequest = '/AllRequests';
  // const routeAbout = '/about';
  const routeLogin = '/login';
  const routeOrders = '/orders/';
  const routePayment = '/payment';
  const routeTourData = '/tour-data';
  const routeEmployees = '/employees';
  const routeWorkSpace = '/workspace';
  const routetranzitData = '/tranzit-data';
  const routePaymentFail = '/payment-fail';
  const routeTouristsData = '/tourists-data';
  const routePaymentSuccess = '/payment-success';
  const routeTranzitPayment = '/tranzit-payment';

  // state
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [companySessionChecked, setCompanySessionChecked] = useState(false);
  const [companySessionExpired, setCompanySessionExpired] = useState(false);

  useEffect(() => {
    loadCompanyEmployeesTypes();

    getCompanySessionExpired().then((sessionExpired: boolean) => {
      setCompanySessionExpired(sessionExpired);
      setCompanySessionChecked(true);
    });
  });

  useEffect(() => {
    const localeValue = getMainStore().locale
    if(localeValue !== 'ru') {
      history.push('/')
    }
  }, [])

  const onBurgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  interface Match {
    params: object;
    isExact: boolean;
    path: string;
    url: string;
  }

  const navLinkItem = {
    isActive: (matchInstance: Match) => {
      if (!matchInstance) {
        return false;
      }
      return true;
    },
  };

  const menuLinks = [
    {
      ...navLinkItem,
      to: `${ROUTE_COMPANY_ACCOUNT}${routeWorkSpace}`,
      title: `Рабочий стол`,
    },
    {
      ...navLinkItem,
      to: `${ROUTE_COMPANY_ACCOUNT}${routeAllRequest}`,
      title: `Все разрешения`,
    },
    {
      ...navLinkItem,
      to: `${ROUTE_COMPANY_ACCOUNT}${routeUsers}`,
      title: `Пользователи`,
    },
    // #1372 скрывает неготовые компоненты
    // {
    //   ...navLinkItem,
    //   to: `${ROUTE_COMPANY_ACCOUNT}${routeAbout}`,
    //   title: `О компании`,
    // },
    {
      ...navLinkItem,
      to: `${ROUTE_COMPANY_ACCOUNT}${routeEmployees}`,
      title: `Сотрудники`,
    },
  ];

  const renderLayout = () => {
    if (companySessionExpired && companySessionChecked) {
      return <Redirect from={`${ROUTE_COMPANY_ACCOUNT}`} to="/sign-in" />;
    }

    return (
      <Layout className={styles['CompanyAccount']}>
        {/* Header */}
        <CompanyAccountHeader isMenuOpen={isMenuOpen} onBurgerClick={onBurgerClick} />

        <Layout>
          {/* Desktop menu */}
          <Sider className={cn(styles['CompanyAccount__menu'], styles['CompanyAccount__menu--desktop'])}>
            <Menu>
              {menuLinks.map((item, itemIndex) => {
                const { to, title, isActive } = item;

                return (
                  // eslint-disable-next-line
                  <Menu.Item key={`key_${itemIndex}`}>
                    <NavLink to={to} isActive={matchInstance => isActive(matchInstance as Match)}>
                      {title}
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu>
          </Sider>

          {/* Mobile menu */}
          {isMenuOpen && (
            <Sider className={cn(styles['CompanyAccount__menu'], styles['CompanyAccount__menu--mobile'])}>
              <div className={styles['CompanyAccount__menu-close']} onClick={() => setMenuOpen(false)} />

              <Menu>
                {menuLinks.map((item, itemIndex) => {
                  const { to, title, isActive } = item;

                  return (
                    // eslint-disable-next-line
                    <Menu.Item key={`key_${itemIndex}`} onClick={() => setMenuOpen(false)}>
                      <NavLink to={to} isActive={matchInstance => isActive(matchInstance as Match)}>
                        {title}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Sider>
          )}

          <Content className={styles['CompanyAccount__content']}>
            <Suspense fallback={<span>Loading...</span>}>
              <Switch>
                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeUsers}`}
                  render={() => {
                    return <Users />;
                  }}
                />
                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeAllRequest}`}
                  render={() => {
                    return <AllRequest />;
                  }}
                />
                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeEmployees}`}
                  render={() => {
                    return <Employees />;
                  }}
                />

                {/* <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeAbout}`}
                  render={() => {
                    return <About />;
                  }}
                /> */}

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeWorkSpace}`}
                  render={() => {
                    return <WorkSpace />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeTouristsData}`}
                  render={() => {
                    return (
                      // вся форма в ЛК
                      <TouristsData
                        addTourUrl={`${ROUTE_COMPANY_ACCOUNT}`}
                        backUrl={`${ROUTE_COMPANY_ACCOUNT}${routeWorkSpace}`}
                        personalArea={true}
                        addTouristsFromExcel={true}
                        touristCreatedIn={'companyAccount'}
                      />
                    );
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeTourData}`}
                  render={() => {
                    return <TourData personalArea={true} addPaymentUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routetranzitData}`}
                  render={() => {
                    return <TranzitData addPaymentUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routePayment}`}
                  render={() => {
                    return <Payment personalArea={true} addPaymentUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeTranzitPayment}`}
                  render={() => {
                    return <TranzitPayment addPaymentUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routePaymentSuccess}`}
                  render={() => {
                    return <PaymentSuccess addPaymentUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routePaymentFail}`}
                  render={() => {
                    return <PaymentFailScreen />;
                  }}
                />

                <Route
                  exact
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeOrders}`}
                  render={() => {
                    return <OrdersPage addClass="orders-page__wrapper--ca" addUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeOrders}:id`}
                  render={() => {
                    return <OrderInfoPage addClass="orders-page__wrapper--ca" addUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route
                  path={`${ROUTE_COMPANY_ACCOUNT}${routeLogin}`}
                  render={() => {
                    return <PersonalAccountAuthPage addUrl={`${ROUTE_COMPANY_ACCOUNT}`} />;
                  }}
                />

                <Route path={match.path} render={() => <Redirect to={`${ROUTE_COMPANY_ACCOUNT}${routeWorkSpace}`} />} />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
      // TODO - double <Layout> ???
    );
  };

  return (
    <>
      {companySessionChecked ? (
        renderLayout()
      ) : (
        <div className={styles['spin-container']}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default observer(CompanyAccount);

import React from 'react';
import cn from 'classnames';

import styles from './Login.module.scss';

interface Props {
  userName: string;
  companyName: string;
  onLogout: () => void;
}

const Login: React.FC<any> = (props: Props) => {
  const { userName, companyName, onLogout } = props;

  return (
    <ul className={styles['Login']}>
      <li className={styles['Login__item']} title={userName}>
        {userName}
      </li>
      <li className={cn(styles['Login__item'], styles['Login__item--separator'])}>|</li>
      <li className={cn(styles['Login__item'], styles['Login__item--company-name'])} title={companyName}>
        {companyName}
      </li>
      <li className={styles['Login__item']} onClick={onLogout}>
        Выйти
      </li>
    </ul>
  );
};

export default Login;

import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
    // Получение всех заявок пользователя в краткой форме.(личный кабинет)
    getAllOrders: (lang: string = ru, sessionId: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}lkGetAllTravelerRequests`, { params: { lang, sessionId } })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Получение подробной информации о конкретной заявке пользователя. (личный кабинет)
    // id - UUID заявки.
    getOrderInfo: (lang: string = ru, sessionId: string, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}lkGetTravelerRequest`, { params: { lang, sessionId, id } })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Получить PNG файл содержащий qr-код.
    //  Id - UUID изображения.
    //  Id изображения содержится в поле участника, получаемых с эндпоинта lkGetTravelerRequest
    getOrderQR: (lang: string = ru, sessionId: string, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getQRCodeFile`, {
                params: { lang, sessionId, id },
                responseType: 'blob',
                headers: {
                    Accept: 'image/png',
                },
            })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Получить PDF файл содержащий разрешение на посещение.
    // Id - UUID PDF файла.
    getOrderPDF: (lang: string = ru, sessionId: string, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getResolutionFile`, {
                params: { lang, sessionId, id },
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                },
            })
            .then((response: HttpResponse) => {
                const { data, error } = response;
                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Получение краткой информации о всех участниках и транспорте пользователя. (личный кабинет)
    getPeopleAndTransport: (lang: string = ru, sessionId: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}lkGetTravelersAndTransports`, {
                params: { lang, sessionId },
            })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },

    // Эндпоинт отдает строку примечаний к маршруту, которую нужно показывать пользователю. Либо пустую строку,
    // если примечаний на текущую дату нет. Id - UUID маршрута.
    getRouteFootnote: (lang: string = ru, id: string): Promise<HttpResponse> => {
        return httpClient
            .get(`${REST_CURRENT}getRouteNotes`, {
                params: { lang, id },
            })
            .then((response: HttpResponse) => {
                const { data, error } = response;

                if (error) {
                    return { data: null, error };
                } else {
                    return { data, error };
                }
            });
    },
};

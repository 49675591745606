import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

interface IProps {
  yaMetrikaId: number;
}

const YandexMetrika: React.FC<IProps> = ({ yaMetrikaId }) => {
  if (yaMetrikaId) {
    return (
      <div>
        <YMInitializer
          version="2"
          accounts={[yaMetrikaId]}
          options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
        />
      </div>
    );
  } else return null;
};

export default YandexMetrika;

import en from './en.json';
import ru from './ru.json';

import enUs from 'antd/es/locale/en_US';
import ruRu from 'antd/es/locale/ru_RU';

export const antdLocaleMapping = {
  en: enUs,
  ru: ruRu,
};

export const messagesMapping = {
  en: en,
  ru: ru,
};

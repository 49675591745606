import React from 'react';

import styles from './CheckoxCustom.module.scss';

interface IProps {
  id: string;
  labelText: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const CheckoxCustom: React.FC<IProps> = ({ id, name = 'checkbox-custom', checked, disabled, labelText, onChange }) => {
  return (
    <div className={styles['CheckoxCustom']}> 
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />

      <label htmlFor={id}>
        <span>{labelText}</span>
      </label>
    </div>
  );
}

export default CheckoxCustom;
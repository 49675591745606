export default {
  AUTO: 'b0752840-6906-9225-110c-36ae37464a4a',

  NORTH_OF_OLKHON_ID: 'e8c119fa-d463-5ac4-2c49-6375efcacd05',
  OLKHON_CLUSTER_NAME: 'Ольхонский кластер',
  OLKHON_CLUSTER_NAME_TRANSLIT: 'Olkhonsky cluster',

  OLKHON_ISLAND_NORTH_RU: 'Север острова Ольхон',
  OLKHON_ISLAND_NORTH_EN: 'North of Olkhon Island',
  OLKHON_ISLAND_NORTH_CH: '奥利洪岛的北线',
};

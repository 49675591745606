import { lazy } from 'react';
import { ROUTES } from '@constants';

const { ROUTE_COMPANY_ACCOUNT } = ROUTES;

// import pages with lazy-loading
const MainPage                 = lazy(() => import('@pages/MainPage/MainPage'));
const CompanyAccount           = lazy(() => import('@pages/CompanyAccount/CompanyAccount'));
const PersonalAccountAuthPage  = lazy(() => import('@pages/PersonalAccountAuthPage/PersonalAccountAuthPage'));
const ForgetPasswordPage       = lazy(() => import('@pages/ForgetPasswordPage/ForgetPasswordPage'));
const ComAccountAuth           = lazy(() => import('@pages/CompanyAccount/CompanyAccountAuth/CompanyAccountAuth'));
const CompanyAccountSignUp     = lazy(() => import('@pages/CompanyAccount/CompanyAccountSingUp/CompanyAccountSignUp'));
const OrdersPageWrapper        = lazy(() => import('@pages/OrdersPage/OrdersPageWrapper'));
const OrderInfoPage            = lazy(() => import('@pages/OrderInfoPage/OrderInfoPage'));
const TouristsData             = lazy(() => import('@pages/TouristsDataScreen/TouristsDataScreen'));
const TourData                 = lazy(() => import('@pages/TourDataScreen/TourDataScreen'));
const TranzitData              = lazy(() => import('@pages/TourDataScreen/partials/TranzitDataScreen/TranzitDataScreen'));
const Payment                  = lazy(() => import('@pages/PaymentScreen/PaymentScreen'));
const TranzitPayment           = lazy(() => import('@pages/TranzitPaymentScreen/TranzitPaymentScreen'));
const PaymentFailScreen        = lazy(() => import('@pages/PaymentFailScreen/PaymentFailScreen'));
const PaymentSuccess           = lazy(() => import('@pages/PaymentSuccessScreen/PaymentSuccessScreen'));

// don't need pages any more
// const TestPage                 = lazy(() => import('@pages/TestPage/TestPage'));
// const VisitRulesPage           = lazy(() => import('@pages/VisitRulesPage/VisitRulesPage'));
// const ContactPassPage          = lazy(() => import('@pages/ContactPassPage'));
// const TariffsPage              = lazy(() => import('@pages/TariffsPage/TariffsPage'));
// const AboutUsPage              = lazy(() => import('@pages/AboutUsPage/AboutUsPage'));

export interface MenuItems {
  pathPattern: string;
  menuLink: string;
  component: any;
  caption: string;
  isIconOnly?: boolean;
  icon?: any;
}

export interface SubMenuItem {
  items: Array<MenuItems | SubMenuItem>;
  caption: string;
}

// Code below demonstrates how we can create SubMenu section
// Remove '/*' '*/' comments and restart components to get this block in menu

// This is RouteItem object that we want to see in User Settings sub menu
const backToHomeRouteItem = {
  pathPattern: '/oopt',
  menuLink: '/',
  component: MainPage,
  caption: 'home',
};

const menuItems: Array<MenuItems | SubMenuItem> = [
  backToHomeRouteItem,
  {
    pathPattern: '/payment',
    menuLink: '/payment',
    component: Payment,
    caption: 'payment',
  },
  {
    pathPattern: '/tranzit-payment',
    menuLink: '/tranzit-payment',
    component: TranzitPayment,
    caption: 'tranzit-payment',
  },
  {
    pathPattern: '/tourists-data',
    menuLink: '/tourists-data',
    component: TouristsData,
    caption: 'tourists',
  },
  {
    pathPattern: '/tour-data',
    menuLink: '/tour-data',
    component: TourData,
    caption: 'tour',
  },
  {
    pathPattern: '/tranzit-data',
    menuLink: '/tranzit-data',
    component: TranzitData,
    caption: 'tranzit',
  },
  {
    pathPattern: '/payment-success',
    menuLink: '/payment-success',
    component: PaymentSuccess,
    caption: 'Success payment',
  },
  {
    pathPattern: '/payment-fail',
    menuLink: '/payment-fail',
    component: PaymentFailScreen,
    caption: 'Fail payment',
  },
  {
    pathPattern: '/login/',
    menuLink: '/login/',
    component: PersonalAccountAuthPage,
    caption: 'PersonalAccountAuthPage',
  },
  {
    pathPattern: '/orders/',
    menuLink: '/orders/',
    component: OrdersPageWrapper,
    caption: 'OrdersPage',
  },
  {
    pathPattern: '/orders/:id',
    menuLink: '/orders/:id',
    component: OrderInfoPage,
    caption: 'OrdersInfoPage',
  },
  {
    pathPattern: '/sign-in',
    menuLink: '/sign-in',
    component: ComAccountAuth,
    caption: 'ComAccountAuth',
  },
  {
    pathPattern: '/sign-up',
    menuLink: '/sign-up',
    component: CompanyAccountSignUp,
    caption: 'CompanyAccountSignUp',
  },
  {
    pathPattern: '/forget-password',
    menuLink: '/forget-password',
    component: ForgetPasswordPage,
    caption: 'ForgetPasswordPage',
  },

  // CompanyAccount pages
  {
    pathPattern: `${ROUTE_COMPANY_ACCOUNT}`,
    menuLink: `${ROUTE_COMPANY_ACCOUNT}`,
    component: CompanyAccount,
    caption: 'CompanyAccount',
  },
  {
    pathPattern: `${ROUTE_COMPANY_ACCOUNT}/users`,
    menuLink: `${ROUTE_COMPANY_ACCOUNT}/users`,
    component: CompanyAccount,
    caption: 'CompanyAccount',
  },
  {
    pathPattern: `${ROUTE_COMPANY_ACCOUNT}/about`,
    menuLink: `${ROUTE_COMPANY_ACCOUNT}/about`,
    component: CompanyAccount,
    caption: 'CompanyAccount',
  },
  {
    pathPattern: `${ROUTE_COMPANY_ACCOUNT}/employees`,
    menuLink: `${ROUTE_COMPANY_ACCOUNT}/employees`,
    component: CompanyAccount,
    caption: 'CompanyAccount',
  },

  // don't need pages any more
  // стартовый тест-экран - не дб на проде
  // {
  //   pathPattern: '/welcome',
  //   menuLink: '/welcome',
  //   component: TestPage,
  //   caption: 'welcome',
  // },
  // {
  //   pathPattern: '/contacts',
  //   menuLink: '/contacts',
  //   component: ContactPassPage,
  //   caption: 'contacts',
  // },
  // {
  //   pathPattern: '/visitRulesPage',
  //   menuLink: '/visitRulesPage',
  //   component: VisitRulesPage,
  //   caption: 'VisitRulesPage',
  // },
  // {
  //   pathPattern: '/tariffsPage',
  //   menuLink: '/tariffsPage',
  //   component: TariffsPage,
  //   caption: 'TariffsPage',
  // },
  // {
  //   pathPattern: '/about-us',
  //   menuLink: '/about-us',
  //   component: AboutUsPage,
  //   caption: 'AboutUsPage',
  // },
];

export default menuItems;

import React, { useEffect, useState } from 'react';

import { find, get } from 'lodash';
import { useAppStore } from '@store';
import { observer } from 'mobx-react';
import { isLogging } from '@configuration/config';
import { SERVER_HOST } from '@configuration/config';
import { ParkShortModel } from '@typing/ParkShortModel';

import { CenteredLoader } from '@components/common';
import defaultParkIcon from '@static/icons/park-icon.png';
import cn from 'classnames';
import styles from './ParkSelection.module.scss';


interface ParkSelectionProps {
  pTerritory: any | null;
  defTerritoryOoptId: string;
  entOoptTerra: string;
  ooptsShort?: any[];
  addCSSClass?: string;
  lang?: string;
  onClickHandlerAdd?: (id: string) => void;
  hiddenParksId?: string[];
}

const ParkSelection = (props: ParkSelectionProps) => {
  const {
    lang,
    ooptsShort,
    pTerritory,
    entOoptTerra,
    hiddenParksId,
    addCSSClass = '',
    defTerritoryOoptId,
    onClickHandlerAdd,
  } = props;

  const {
    orderDataStore: { oopt, setPrevOOPTName, setOOPT },
    appSettingsStore: { loadOoptsShort, loadAllAppSettings },
    mapStore: { setParkId, setZoom, setCenter },
    selectedUserTerritoryStore: {
      setDocumentTypeName: setDocumentTypeNameSelectedUserTerritoryStore,
      setTerritoryId: setTerritoryIdSelectedUserTerritoryStore,
      defaultTerritoryOoptId: defaultTerritoryOoptIdSelectedUserTerritoryStore,
    },
  } = useAppStore();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    loadOoptsShort(lang).then((ok: boolean) => {
      if (isLogging) {
        // eslint-disable-next-line
        console.log('Short List Parks Loaded', ok);
      }

      if (ok) {
        loadAllAppSettings(lang).then((sett: any) => {
          if (sett) {
            setLoading(false);

            if (isLogging) {
              // eslint-disable-next-line
              console.log('SELECTED park id', get(oopt, ['id'], undefined));
            }

            // Behave rules setter
            setDocumentTypeNameSelectedUserTerritoryStore('rules');
            setTerritoryIdSelectedUserTerritoryStore(defaultTerritoryOoptIdSelectedUserTerritoryStore);

            if (sett && get(oopt, ['id']) === defTerritoryOoptId) {
              let defaultPark = find(sett[entOoptTerra], (x: any) => x['id'] === defTerritoryOoptId);

              if (defaultPark) {
                setOOPT(defaultPark);
                setParkId(defaultPark['id']);
                setZoom(defaultPark['defaultMapZoom']); //"defaultCoordN":"52.56683" "defaultCoordE":"105.80283" "defaultMapZoom":8 Pribaikal
                setCenter({
                  lat: defaultPark['defaultCoordN'],
                  lng: defaultPark['defaultCoordE'],
                });
              }
            }
          }
        });
      }
    });
    // TODO inspect the issue with oopt keyword (non-stop re-rendering)
    // eslint-disable-next-line
  }, [
    defTerritoryOoptId,
    lang,
    loadAllAppSettings,
    loadOoptsShort,
    defaultTerritoryOoptIdSelectedUserTerritoryStore,
    setCenter,
    setDocumentTypeNameSelectedUserTerritoryStore,
    setTerritoryIdSelectedUserTerritoryStore,
    setOOPT,
    setParkId,
    setZoom, // ???
    entOoptTerra,
    isLoading,
  ]);

  return (
    <div className={cn(styles['ParkSelection'], styles[`${addCSSClass}`])}>
      {!isLoading ? (
        ooptsShort
          ?.filter((park: ParkShortModel) => {
            return hiddenParksId ? hiddenParksId.every((id: string) => id !== park.id) : true;
          })
          .map((park: any) => {
            return (
              <div
                key={park.id}
                className={cn(
                  styles['ParkSelection__item'],
                  park.id === get(oopt, ['id'], undefined) ? styles['ParkSelection__item--active'] : '',
                )}
                onClick={() => {
                  setPrevOOPTName();
                  setOOPT(find(pTerritory, (item: any) => item.id === park.id));
                  // map state
                  setParkId(park.id);

                  // "defaultCoordN":"52.56683" "defaultCoordE":"105.80283" "defaultMapZoom":8 Pribaikal
                  setZoom(park.defaultMapZoom);
                  setCenter({
                    lat: park.defaultCoordN,
                    lng: park.defaultCoordE,
                  });
                  setDocumentTypeNameSelectedUserTerritoryStore('rules');
                  setTerritoryIdSelectedUserTerritoryStore(park.id);
                  if (onClickHandlerAdd) onClickHandlerAdd(park.id);
                }}
              >

                {/* parks logos from API */}
                {park.logoId ? (
                  <img 
                    className={styles['ParkSelection__logo']} 
                    src={`${SERVER_HOST}api/getFile?lang=ru&id=${park.logoId}`} 
                    alt={`${park.logoId}`} 
                  />
                ) : (
                  // default park logo
                  <img className={styles['ParkSelection__logo']} src={defaultParkIcon} />
                )}

                {/* park name */}
                <p className={styles['ParkSelection__text']}>{park.name}</p>
              </div>
            );
          })
      ) : (
        // loader
        <div className={styles['ParkSelection__loader']}>
          <CenteredLoader />
        </div>
      )}
    </div>
  );
};

export default observer(ParkSelection);

import { action, autorun, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { toast } from 'react-toastify';

import { ITraveler, ITravelerPA, Tourist } from '@typing/Traveler';
import { IOrder, IOrderTransport, INormalizedOrderInfo, IPeopleTransport } from '@typing/OrderData';
import { UserStoreOrderView } from '@typing/enums';
import { version } from '@configuration/config';
import { LocalStorageUtils } from '@utils';
import { UserService } from '@services';

const persistenceKey = version.split('.').join('-');
const { getAllOrders, getOrderInfo, getOrderQR, getOrderPDF, getPeopleAndTransport, getRouteFootnote } = UserService;

export default class UserStore {
  @persist @observable userName: string = '';

  @observable email: string = '';

  @observable code: string = '';

  @observable errors: {
    email?: string | null;
    code?: string | null;
    common: string | null;
  } = { email: null, code: null, common: null };

  @observable touched: {
    email?: boolean;
    code?: boolean;
  } = { email: false, code: false };

  @observable authStatus: {
    status: 'success' | 'error' | 'waiting' | null;
    message: string | null;
  } = { status: null, message: null };

  @observable codeStatus: {
    status: boolean | null;
    message: string | null;
  } = { status: null, message: null };

  @observable sessionId: string = '';

  @observable replyMessageId: string = '';

  @observable orders: IOrder[] = [];

  @observable orderInfo: INormalizedOrderInfo | null = null;

  @observable orderView: UserStoreOrderView = UserStoreOrderView.orders;

  @observable loading: boolean = false;

  @observable loadingPDF: boolean = false;

  @observable visibleQR: boolean = false;

  @observable peopleTransport: IPeopleTransport | null = null;

  @observable newOrderPeople: Tourist[] = [];

  @observable timer: number = 0;

  @observable timerId: number = 0;

  @persist @observable cookie: boolean = false;

  constructor() {
    this.loadStore();
    autorun(() => {
      this.saveStore();
    });
  }

  private saveStore = () => {
    LocalStorageUtils.write(
      'UserStore',
      JSON.stringify({
        email: this.email,
        touched: this.touched,
        authStatus: this.authStatus,
        codeStatus: this.codeStatus,
        sessionId: this.sessionId,
        userName: this.userName,
        orders: this.orders,
        orderInfo: this.orderInfo,
        cookie: this.cookie,
        peopleTransport: this.peopleTransport,
      }),
    );
  };

  @action
  private loadStore = () => {
    // Object.assign(this, LocalStorageUtils.read('UserStore') || {});
    Object.assign(this, LocalStorageUtils.read(`UserStore${persistenceKey}`) || {});
  };

  @action
  setEmail = (email: string) => {
    this.email = email;
  };

  @action
  setOrders = (orders: IOrder[]) => {
    this.orders = orders;
  };

  @action
  setCode = (code: string) => {
    this.code = code;
  };

  @action
  setReplyMessageId = (replyMessageId: string) => {
    this.replyMessageId = replyMessageId;
  };

  @action
  setCodeStatus = (status: { status: boolean; message: string }) => {
    this.codeStatus = status;
  };

  @action
  setErrors = (errors: { email?: string | null; code?: string; common?: string }): void => {
    this.errors = {
      ...this.errors,
      ...errors,
    };
  };

  @action
  setTouched = (input: { email?: boolean; code?: boolean }) => {
    this.touched = {
      ...this.touched,
      ...input,
    };
  };

  @action
  setSessionId = (sessionId: string) => {
    this.sessionId = sessionId;
  };

  @action
  setUserName = (userName: string) => {
    this.userName = userName;
  };

  @action
  loadOrders = (locale: string | undefined = 'en', sessionId: string) => {
    getAllOrders(locale, sessionId).then(({ data, error }) => {
      if (!error) {
        runInAction('setOrders', () => {
          this.setOrders(JSON.parse(data.replyMessage));
        });
      } else {
        toast.error(error);
        this.setErrors({ common: error });
      }
    });
  };

  @action
  setAuthStatus = (status: { status: 'success' | 'error' | 'waiting' | null; message: string | null }) => {
    this.authStatus = status;
  };

  @action
  dropTimerId = () => {
    this.timerId = 0;
  };

  @action
  setTimer = (sec: number) => {
    const { setInterval, setTimeout, clearInterval } = window;
    this.timer = sec / 1000;

    if (!this.timerId) {
      this.timerId = setInterval(() => {
        this.timer = this.timer - 1;
      }, 1000);

      setTimeout(() => {
        clearInterval(this.timerId);
        this.dropTimerId();

        this.authStatus = {
          ...this.authStatus,
          status: 'waiting',
        };
      }, sec);
    } else {
      clearInterval(this.timerId);
    }
  };

  @action
  getOrderInfo = async (locale: string = 'en', orderId: string) => {
    this.setLoading(true);
    if (this.sessionId) {
      const { data, error } = await getOrderInfo(locale, this.sessionId, orderId);
      const idx = this.orders.findIndex(item => item.id === orderId);
      const resolutionId = idx >= 0 && this.orders[idx].resolutionId;

      if (!error) {
        const orderInfo = JSON.parse(data.replyMessage);

        const mainTraveler = orderInfo.travelers.find((traveler: ITravelerPA) => {
          return !!traveler.qrCodeId;
        });

        if (mainTraveler) {
          const { qrCodeId } = mainTraveler;
          const { data: dataQR, error: errorQR } = await getOrderQR(locale, this.sessionId, qrCodeId);

          if (!errorQR) {
            const qrCode = { [qrCodeId]: URL.createObjectURL(dataQR) };

            this.setOrderInfo({
              ...orderInfo,
              resolutionId: resolutionId,
              qrURL: qrCode,
              mainTraveler: mainTraveler,
            });
          } else {
            toast.error(errorQR);
            // eslint-disable-next-line
            console.error('getOrderQR:', new Error(errorQR));
          }
        }
      } else {
        toast.error(error);
        // eslint-disable-next-line
        console.error('getOrderInfo:', new Error(error));
      }
    }
  };

  @action
  getOrderPDF = async (locale: string, sessionId: string, id: string) => {
    const { data, error } = await getOrderPDF(locale, sessionId, id);

    if (!error) {
      return URL.createObjectURL(data);
    } else {
      toast.error(error);
      // eslint-disable-next-line
      console.error('getOrderQR:', new Error(error));
      return;
    }
  };

  @action
  setOrderInfo = (orderInfo: INormalizedOrderInfo) => {
    this.orderInfo = orderInfo;
    this.setLoading(false);
  };

  @action
  setLoading = (loadingStatus: boolean) => {
    this.loading = loadingStatus;
  };

  @action
  setLoadingPDF = (loadingPDF: boolean) => {
    this.loadingPDF = loadingPDF;
  };

  @action
  setVisibleQR = (statusQR: boolean) => {
    this.visibleQR = statusQR;
  };

  @action
  setOrderView = (view: UserStoreOrderView) => {
    this.orderView = view;
  };

  @action
  setPeopleTransport = (locale: string | undefined, sessionID: string) => {
    getPeopleAndTransport(locale, sessionID).then(({ data, error }) => {
      if (!error) {
        const emptyArr: any = [];
        const peopleTransport = emptyArr.concat(...JSON.parse(data.replyMessage));
        let initialId = 0;

        peopleTransport.forEach((item: any) => {
          initialId = initialId + 1;
          item.id = initialId;
        });

        const people: Tourist[] = peopleTransport.filter((item: ITraveler) => item.hasOwnProperty('lastName'));
        const transport: IOrderTransport[] = peopleTransport.filter((item: IOrderTransport) => {
          return item.hasOwnProperty('model');
        });
        const peopleTransportObj = { people, transport };
        if (peopleTransportObj) {
          runInAction('setPeopleTransport', () => {
            this.peopleTransport = peopleTransportObj;
          });
        }
      } else {
        toast.error(error);
        // eslint-disable-next-line
        console.error('getOrderInfo:', new Error(error));
      }
    });
  };

  @action
  getRouteFootnote = async (locale: string | undefined, id: string) => {
    const { data, error } = await getRouteFootnote(locale, id);

    if (!error) {
      return data.replyMessage;
    } else {
      toast.error(error);
      // eslint-disable-next-line
      console.error('getOrderInfo:', new Error(error));
    }
  };

  @action
  setNewOrderPeople = (personId: string) => {
    const newPerson =
      this.peopleTransport &&
      this.peopleTransport.people.find(item => {
        return item.id.toString() === personId;
      });

    if (newPerson) {
      this.newOrderPeople.push(newPerson);
    }
  };

  @action
  deleteOneOrderPeople = (personId: string) => {
    const idx =
      this.newOrderPeople &&
      this.newOrderPeople.findIndex(item => {
        return item.id.toString() === personId;
      });
    this.newOrderPeople = [...this.newOrderPeople.slice(0, idx), ...this.newOrderPeople.slice(idx + 1)];
  };

  @action
  clearNewOrderPeople = () => {
    this.newOrderPeople = [];
  };

  @action
  setDefaultValue = () => {
    this.email = '';
    this.code = '';
    this.errors = { email: null, code: null, common: null };
    this.touched = { email: false, code: false };
    this.authStatus = { status: null, message: null };
    this.codeStatus = { status: null, message: null };
    this.orders = [];
  };

  @action
  setCookie = () => {
    this.cookie = true;
  };
}

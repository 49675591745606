import moment from 'moment-timezone';

export const isRefreshTokenValid = (
    refreshExpiresAt:
        | string
        | number
        | void
        | moment.Moment
        | Date
        | (string | number)[]
        | moment.MomentInputObject
        | undefined,
) => {
    if (refreshExpiresAt) {
        const now = moment.utc();
        const expires = moment.utc(refreshExpiresAt);
        const isAbleToRefresh = now.isBefore(expires);

        return isAbleToRefresh;
    }

    return false;
};

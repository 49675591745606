import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getMainStore } from '@cuba-platform/react';
import { Modal, Select } from 'antd';

import { RussiaFlag, UKFlag } from '@components/common/Icons';
import './LanguageSwitcher.scss';

const { confirm } = Modal;

const LanguageSwitcher: React.FC = (props: any) => {
  const history = useHistory();
  const [localeValue, setLocaleValue] = useState(getMainStore().locale);
  const { intl } = props;

  const handleChange = (locale: string) => {
    confirm({
      title: intl.formatMessage({ id: 'languageSwitcher.title' }),
      content: intl.formatMessage({ id: 'languageSwitcher.content' }),

      onOk() {
        getMainStore().setLocale(locale);
        setLocaleValue(locale);
        history.push('/');
      },
    });
  };

  return (
    <Select
      size='large'
      value={localeValue}
      onChange={handleChange}
      defaultValue={localeValue}
      className='language-switcher'
    >
      <Select.Option value='en'>
        <UKFlag />
      </Select.Option>

      <Select.Option value='ru'>
        <RussiaFlag />
      </Select.Option>
    </Select>
  );
};

export default injectIntl(LanguageSwitcher);

import { action, observable } from 'mobx';

import { PagesService } from '@services';

const { getProtectedTerritoryFile } = PagesService;

// Стор с данными для простых страниц - контакты, тарифы, правила посещения, etc.
export default class PagesStore {
    // Строка для вставки HTML на странице контактов - /contacts
    @observable contactsHTMLString: string = '';

    // Строка для вставки HTML на странице тарфиов - /tariffsPage
    @observable tariffsHTMLString: string = '';

    // Строка для вставки HTML на странице правил посещения - /visitRulesScreen
    @observable visitRulesHTMLString: string = '';

    @action
    setContactsHTMLString = (htmlString: string) => {
        this.contactsHTMLString = htmlString;
    };

    @action
    setTariffsHTMLString = (htmlString: string) => {
        this.tariffsHTMLString = htmlString;
    };

    @action
    setVisitRulesHTMLString = (htmlString: string) => {
        this.visitRulesHTMLString = htmlString;
    };

    @action
    loadProtectedTerritoryFile = async (territoryId: string, name: 'contacts' | 'tariffs' | 'rules') => {
        await getProtectedTerritoryFile(territoryId, name).then(({ data, error }) => {
            if (data && !error) {
                const { replyMessage } = data;

                switch (name) {
                    case 'contacts':
                        this.setContactsHTMLString(replyMessage);
                        break;
                    case 'tariffs':
                        this.setTariffsHTMLString(replyMessage);
                        break;
                    case 'rules':
                        this.setVisitRulesHTMLString(replyMessage);
                        break;
                    default:
                        break;
                }
            }
        });
    };
}

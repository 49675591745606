import { action, computed, observable, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import { toast } from 'react-toastify';

import { LocalStorageUtils } from '@utils';
import { version } from '@configuration/config';
import { CompanyAccountService } from '@services';
import {
  IEditUserData,
  IEditEmployeeData,
  IAddedEmployeeData,
  ICompanyAccountEmployee,
  ICompanyAccountUser,
  CompanyEmployeesType,
  City,
} from '@typing/CompanyAccount';
import { HttpResponse } from '@api';
import { ICompanyAccountResponse } from '@typing/Services';

const persistenceKey = version.replace(/\.+/g, '-');

const {
  getUsers,
  addUser,
  editUser,
  deleteUser,
  loginCompany,
  logoutCompany,
  getCompanyAllOptions,
  getEmployees,
  editEmployee,
  addEmployee,
  deleteEmployee,
  getCompanyResolutions,
} = CompanyAccountService;

interface IAddedUserData {
  rights?: 'administrator' | 'manager';
  adminName: string;
  adminSurname: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface CompanyOption {
  objectType: string;
  id: string;
  name: string;
  index: number;
}

export default class CompanyAccountStore {
  constructor() {
    this.loadStore();
  }

  @persist @observable companyName: string = '';

  @persist @observable shortCompanyName: string = '';

  @persist @observable firmName: string = '';

  @persist @observable companySessionId: string = '';

  @observable companyAllResolutions = null;

  @observable companyNotAllResolutions = {};

  @observable companyUserId: string = '';

  @observable users: ICompanyAccountUser[] = [];

  @observable employeesTypes: CompanyEmployeesType[] = [];

  @observable employees: ICompanyAccountEmployee[] = [];

  @observable isEmployeesLoading: boolean = false;

  @computed get activeUsers() {
    return this.users.filter((user: ICompanyAccountUser) => user.active);
  }

  @computed get firedUsers() {
    return this.users.filter((user: ICompanyAccountUser) => !user.active);
  }

  @computed get activeEmployees() {
    return this.employees.filter((employee: ICompanyAccountEmployee) => employee.active);
  }

  @computed get activeEmployeesForSelect() {
    return this.activeEmployees.map((employee: ICompanyAccountEmployee) => {
      const { firstName, lastName, midName, userId } = employee;

      return {
        label: `${lastName} ${firstName} ${midName}`,
        value: userId,
      };
    });
  }

  @computed get firedEmployees() {
    return this.employees.filter((employee: ICompanyAccountEmployee) => !employee.active);
  }

  @action
  private loadStore = () => {
    Object.assign(this, LocalStorageUtils.read(`CompanyAccountStore${persistenceKey}`));
  };

  @action
  setIsEmployeesLoading = (isEmployeesLoading: boolean) => {
    this.isEmployeesLoading = isEmployeesLoading;
  };

  @action
  logoutCompany = async (sessionId: string) => {
    return logoutCompany(sessionId);
  };

  @action
  loginCompany = async ({
    login,
    password,
    answer,
    captchaCode,
  }: {
    login: string;
    password: string;
    answer: '';
    captchaCode: '';
  }): Promise<ICompanyAccountResponse | null> => {
    return loginCompany({ login, password, answer, captchaCode }).then((result: HttpResponse) => {
      const { data } = result;
      if (data) {
        const { success, replyMessage, replyInfo, sessionId } = data;
        let resultData: any = { success, replyMessage, sessionId };
        if (success) {
          const { userName, shortCompanyName, companyName, userId, firmName } = JSON.parse(replyInfo as string);

          resultData = {
            ...resultData,
            userName,
            companyName,
            shortCompanyName,
            firmName,
            companyUserId: userId,
          };
        } else {
          const { captcha: captchaData, sessionExpired } = data;
          const { compare, message, captcha, captchaCode: captchaCodeResult } = captchaData;

          resultData = {
            ...resultData,
            compare,
            message,
            captcha,
            sessionExpired,
            captchaCode: captchaCodeResult,
          };
        }
        return resultData;
      }
    });
  };

  @action
  addCompanyUser = async (sessionId: string, addedUserData: IAddedUserData) => {
    const { email, password, adminName, adminSurname, rights } = addedUserData;
    const administrator = rights === 'administrator' ? true : false;

    const jsonFullData = `[{"objectType":"JuristicAccount","administrator":${administrator},"login":"${email}","password":"${password}","userName":"${adminName} ${adminSurname}"}]`;

    const data = {
      lang: 'ru',
      jsonFullData,
    };

    const { success, replyMessage } = await addUser(sessionId, JSON.stringify(data));

    return { success, replyMessage };
  };

  @action
  getCompanySessionExpired = async () => {
    return getUsers(this.companySessionId)
      .then(({ data: { sessionExpired } }) => {
        return sessionExpired;
      })
      .catch(error => {
        // eslint-disable-next-line
        console.error(error);
      });
  };

  @action
  editCompanyUser = async (editUserData: IEditUserData) => {
    const data = {
      sessionId: '',
      userId: '',
      oldPassword: '',
      setUserName: '',
      setPassword: '',
      setAdmin: '',
      setActive: '',
    };

    return editUser({
      ...data,
      ...editUserData,
    } as IEditUserData);
  };

  @action
  deleteCompanyUser = async (sessionId: string, userId: string) => {
    return deleteUser(sessionId, userId);
  };

  @action
  setCompanyUsers = (users: ICompanyAccountUser[]) => {
    this.users = users;
  };

  @action
  setCompanyUserId = (companyUserId: string) => {
    this.companyUserId = companyUserId;
  };

  @action
  loadCompanyUsers = async (sessionId: string) => {
    const { data, error } = await getUsers(sessionId);

    if (data) {
      const { success, replyMessage } = data;

      if (success) {
        const users = toJS(JSON.parse(replyMessage)).map((item: ICompanyAccountUser, index: number) => {
          return { ...item, index };
        });

        this.setCompanyUsers(users);
      }
    } else {
      toast.error(error);
    }
  };

  @action
  addCompanyEmployee = async (sessionId: string, addedEmployeeData: IAddedEmployeeData, cities: any) => {
    const { firstName, lastName, midName, employeeTypeId, countryId = '', cityId = '' } = addedEmployeeData;
    const city = cities.find((item: City) => {
      return item.cityUUID == cityId;
    });

    const jsonFullData = `[{"objectType":"JuristicEmployee","firstName":"${firstName}","lastName":"${lastName}","midName":"${midName}","employeeTypeId":"${employeeTypeId}","countryId":"${countryId}","cityId":"${cityId}","regionId":"${
      city ? city?.regionUUID : ''
    }",}]`;
    const data = {
      jsonFullData,
    };

    return addEmployee(sessionId, JSON.stringify(data));
  };

  @action
  editCompanyEmployee = async (editEmployeeData: IEditEmployeeData) => {
    const data = {
      sessionId: '',
      userId: '',
      setFirstName: '',
      setMidName: '',
      setLastName: '',
      setType: '',
      setActive: '',
    };

    return editEmployee({
      ...data,
      ...editEmployeeData,
    } as IEditEmployeeData);
  };

  @action
  deleteCompanyEmployee = async (sessionId: string, userId: string) => {
    return deleteEmployee(sessionId, userId);
  };

  @action
  setCompanyEmployees = (employees: ICompanyAccountEmployee[]) => {
    this.employees = employees;
  };

  @action
  loadCompanyEmployees = async (sessionId: string) => {
    this.setIsEmployeesLoading(true);
    const { data, error } = await getEmployees(`${sessionId}`);

    if (data) {
      const { replyMessage } = data;
      const employees = toJS(JSON.parse(replyMessage)).map((item: ICompanyAccountUser, index: number) => {
        return { ...item, index };
      });

      this.setCompanyEmployees(employees);
      this.setIsEmployeesLoading(false);
    } else {
      toast.error(error);
      this.setIsEmployeesLoading(false);
    }
  };

  @action
  setCompanySessionId = (companySessionId: string) => {
    this.companySessionId = companySessionId;
  };

  @action
  setCompanyName = (companyName: string) => {
    this.companyName = companyName;
  };

  @action
  setShortCompanyName = (shortCompanyName: string) => {
    this.shortCompanyName = shortCompanyName;
  };

  @action
  setFirmName = (firmName: string) => {
    this.firmName = firmName;
  };

  @action
  setCompanyEmployeesTypes = (employeesTypes: CompanyEmployeesType[]) => {
    this.employeesTypes = employeesTypes;
  };

  @action
  setСompanyAllResolutions = (companyAllResolutions: any) => {
    this.companyAllResolutions = companyAllResolutions;
  };

  @action
  loadCompanyEmployeesTypes = async () => {
    await getCompanyAllOptions().then(response => {
      const { error, data } = response!;

      if (!error) {
        const { replyMessage } = data;

        const types = JSON.parse(replyMessage)
          .filter((item: CompanyOption) => item.objectType === 'JuristicEmployeeType')
          .sort(
            (a: CompanyOption, b: CompanyOption) =>
              parseInt(a.index as unknown as string, 10) - parseInt(b.index as unknown as string, 10),
          )
          .map((item: CompanyOption) => {
            const { id: value, name: label } = item;

            return {
              value,
              label,
            };
          });

        this.setCompanyEmployeesTypes(types);
      }
    });
  };

  @observable resolutionsWasLoad: boolean = false;

  @action
  loadCompanyResolutions = (sessionId: string, isSmall?: boolean) => {
    getCompanyResolutions(sessionId, isSmall).then(response => {
      const { error, data } = response!;

      if (!error) {
        const { replyMessage } = data;

        try {
          this.setСompanyAllResolutions(JSON.parse(replyMessage));
          this.resolutionsWasLoad = true;
        } catch (e) {
          return;
        }
      }
    });
  };

  setLoginCompanyData = (
    {
      sessionId,
      shortCompanyName,
      companyName,
      firmName,
      companyUserId,
    }: {
      sessionId: string;
      shortCompanyName: string;
      companyName: string;
      firmName: string;
      companyUserId: string;
    } = {
      sessionId: '',
      shortCompanyName: '',
      companyName: '',
      firmName: '',
      companyUserId: '',
    },
  ) => {
    this.setCompanySessionId(sessionId);
    this.setCompanyName(companyName);
    this.setShortCompanyName(shortCompanyName);
    this.setCompanyUserId(companyUserId);
    this.setFirmName(firmName);
  };
}

import { action, observable } from 'mobx';

const privilegesObj = [
    { label: 'Без льгот', id: '1' },
    { label: 'Инвалиды войны', id: '2' },
    { label: 'Участники Великой Отечественной Войны;', id: '3' },
    {
        label: 'Лица, удостоенные звания Героя Советского Союза, Героя Российской Федерации или являющиеся полными кавалерами ордена Славы;',
        id: '4',
    },
    {
        label: 'Лица, удостоенные звания Героя Социалистического Труда, Героя Труда Российской Федерации либо награжденные орденом Трудовой Славы трех степеней;',
        id: '5',
    },
    {
        label: 'Ветераны боевых действий из числа лиц, указанных в пункте 1 статьи 3 Федерального закона «О ветеранах»;',
        id: '6',
    },
    {
        label: 'Военнослужащие, проходившие военную службу в воинских частях, учреждениях, военно-учебных заведениях, не входивших в состав действующей армии, в период с 22 июня 1941 г. по 3 сентября 1945 г. не менее шести месяцев, военнослужащие, награжденные орденами или медалями СССР за службу в указанный период;',
        id: '7',
    },
    { label: 'Лица, награжденные знаком «Жителю блокадного Ленинграда»;', id: '8' },
    {
        label: 'Лица, работавшие в период Великой Отечественной войны на объектах противовоздушной обороны, местной противовоздушной обороны, на строительстве оборонительных сооружений, военно-морских баз, аэродромов и других военных объектов в пределах тыловых границ действующих фронтов, операционных зон действующих флотов, на прифронтовых участках железных и автомобильных дорог, а также члены экипажей судов транспортного флота, интернированных в начале Великой Отечественной войны в портах других государств;',
        id: '9',
    },
    {
        label: 'Члены семей погибших (умерших) инвалидов войны, участников Великой Отечественной войны и ветеранов боевых действий, члены семей погибших в Великой Отечественной войне лиц из числа личного состава групп самозащиты объектов и аварийных команд местной противовоздушной обороны, а также члены семей погибших работников госпиталей и больниц г. Ленинграда;',
        id: '10',
    },
    { label: 'Инвалиды и сопровождающие их лица;', id: '11' },
    { label: 'Дети-инвалиды и сопровождающие их лица;', id: '12' },
    {
        label: 'Лица, подвергшиеся воздействию радиации вследствие катастрофы на Чернобыльской АЭС, а также вследствие ядерных испытаний на Семипалатинском полигоне, и приравненные к ним категории граждан;',
        id: '13',
    },
    { label: 'Дети дошкольного и школьного возраста;', id: '14' },
    {
        label: 'Малоимущие семьи, малоимущие одиноко проживающие граждане и иные категории граждан, предусмотренные Федеральным законом «О государственной социальной помощи»;',
        id: '15',
    },
    { label: 'Граждане Российской Федерации пенсионного возраста;', id: '16' },
    { label: 'Многодетные родители, воспитывающие несовершеннолетних детей;', id: '17' },
    {
        label: 'Дети-сироты, дети, оставшиеся без попечения родителей, лица из числа детей-сирот и детей, оставшихся без попечения родителей.',
        id: '18',
    },
];

export default class TouristStore {
    privileges = privilegesObj;

    tourists = {
        'id-s2345-2455': {
            name: 'Alex',
            surname: 'Terr',
            midlName: null,
            citizenship: 'Russia',
            phone: '79996490000',
            email: 'alxter@gmail.com',
            privilege: { label: 'Без льгот', id: '1' },
        },
    };

    tourist = this.tourists['id-s2345-2455'].privilege;

    @observable
    touristPrivilege: { label: string; id: string } = { label: '', id: '' };

    @observable
    isModalPrivileges: boolean = false;

    @action
    setModalPrivileges = (isModalVisible: boolean) => {
        this.isModalPrivileges = isModalVisible;
    };
}

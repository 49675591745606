export enum UserStoreOrderView {
  orders = 'orders',
  people = 'people',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export enum DocumentType {
  PASSPORT = '981b3dcd-f9d8-7109-c2a5-6a02600d6ed6',
  FOREIGN_PASSPORT = '5e174c93-5a49-e304-1a26-ec7253c18517',
  BIRTH_CERTIFICATE = 'f77ec88b-5963-450d-f847-732f67d42b6b',
}

export enum LegalFormOptions {
  entrepreneur = '7e061c2d-1a77-36c1-c8f8-797b88460a46',
  company = '54df0c9f-f44e-43e0-37e6-94549d85e4b5',
}

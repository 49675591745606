import { action, observable } from 'mobx';
import { getMainStore } from '@cuba-platform/react';
import { persist } from 'mobx-persist';
import { DirectoratesService } from '@services';
import { toast } from 'react-toastify';

const { getDirectorates } = DirectoratesService;

export default class DirectorateStore {
  @persist('list') @observable directorates: any[] = [];

  @action
  setDirectorates = (directorates: any[]) => {
    this.directorates = directorates;
  };

  // return promisse
  @action
  loadDirectorates = async () => {
    const { data, error } = await getDirectorates(getMainStore().locale);

    if (!error) {
      this.setDirectorates(data);
    } else {
      toast.error(error);
      // eslint-disable-next-line
      console.error(error);
    }

    return data;
  };
}

import { httpClient, HttpResponse } from '@api';
import { API } from '@constants';
import { IServerResponse, IValues } from '@typing/Services';
import { IEditUserData, IEditEmployeeData } from '@typing/CompanyAccount';

const { REST_CURRENT, LANGUAGES } = API;
const { ru } = LANGUAGES;

export default {
  // Восстановление пароля
  sendCode: (values: any) => {
    const { email, answer, captchaCode } = values;
    return httpClient.post(`${REST_CURRENT}passwordReset`, { email, answer, captchaCode });
  },

  checkCode: (values: IValues, sendedEmail: string) => {
    const { code, answer, captchaCode } = values;
    return httpClient.post(`${REST_CURRENT}passwordCheckCode`, { email: sendedEmail, code, answer, captchaCode });
  },

  enterNewPassword: (values: IValues, sendedEmail: string, sendedСode: string) => {
    return httpClient.post(`${REST_CURRENT}passwordNew`, {
      email: sendedEmail,
      code: sendedСode,
      password: values.newPassword,
    });
  },

  // Получить разрешения компании
  getCompanyResolutions: (sessionId: string, isSmall?: boolean): Promise<HttpResponse> => {
    const size = isSmall ? '&from=0&to=5' : '&from=&to=';
    return httpClient
      .get(`${REST_CURRENT}juristicGetResolutions?sessionId=${sessionId}${size}`)
      .then((response: HttpResponse) => {
        const { data, error } = response;
        if (error) {
          return { data: null, error };
        } else {
          return { data, error };
        }
      });
  },

  // Получить все типы компаний и все типы сотрудников компаний
  getCompanyAllOptions: (lang: string = ru): Promise<HttpResponse> => {
    return httpClient
      .get(`${REST_CURRENT}juristicGetAllOptions`, { params: { lang } })
      .then((response: HttpResponse) => {
        const { data, error } = response;
        if (error) {
          return { data: null, error };
        } else {
          return { data, error };
        }
      });
  },

  // Регистрировать компанию
  registerCompany: (data: any): Promise<IServerResponse> => {
    return httpClient.post(`${REST_CURRENT}juristicRegistration`, data).then((response: HttpResponse) => {
      return response.data;
    });
  },

  // Авторизовать компанию
  loginCompany: ({
    login,
    password,
    answer,
    captchaCode,
  }: {
    login: string;
    password: string;
    answer: '';
    captchaCode: '';
  }): Promise<HttpResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicAuth`, { login, password, answer, captchaCode })
      .then((response: HttpResponse) => {
        const { data, error } = response;
        if (error) {
          return { data: null, error };
        } else {
          return { data, error };
        }
      });
  },

  // Разлогинить компанию
  logoutCompany: (sessionId: string): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicExit`, null, { params: { sessionId } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },

  // Загрузить пользователей компании
  getUsers: (sessionId: string): Promise<HttpResponse> => {
    return httpClient.get(`${REST_CURRENT}juristicGetUsers`, { params: { sessionId } });
  },

  // Добавить пользователя компании
  addUser: (sessionId: string, data: string): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicAddUser`, data, { params: { sessionId } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },

  // Редактировать пользователя компании
  editUser: (data: IEditUserData): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicEditUser`, null, { params: { ...data } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },

  // Удалить пользователя компании
  deleteUser: (sessionId: string, userId: string): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicDeleteUser`, null, { params: { sessionId, userId } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },

  // Сотрудники компании
  getEmployees: (sessionId: string): Promise<HttpResponse> => {
    return httpClient.get(`${REST_CURRENT}juristicGetEmployees`, { params: { sessionId } });
  },

  // Редактировать сотрудника компании
  editEmployee: (data: IEditEmployeeData): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicEditEmployee`, null, { params: { ...data } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },

  // Добавить сотрудника компании
  addEmployee: (sessionId: string, data: string): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicAddEmployee`, data, { params: { sessionId } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },

  // Удалить пользователя компании
  deleteEmployee: (sessionId: string, userId: string): Promise<IServerResponse> => {
    return httpClient
      .post(`${REST_CURRENT}juristicDeleteEmployee`, null, { params: { sessionId, userId } })
      .then((response: HttpResponse) => {
        return response.data;
      });
  },
};

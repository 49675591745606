import React from 'react';
import errorImg from '@static/images/error.svg'

import styles from './ErrorWindow.module.scss';

const ErrorWindow: React.FC = () => {
  return (
    <div className={styles['ErrorWindow']}>
      <div className={styles['ErrorWindow__wrapper']}>
        <img src={errorImg} className={styles['ErrorWindow__image']} alt='error'/>
        <div className={styles['ErrorWindow__text']}>
          <div className={styles['ErrorWindow__title']}>Что-то пошло не так...</div>
          <div>Возможные причины:</div>
          <ul>
            <li>Проблемы с интернетом.</li>
            <li>Наш сервер устал и отказывается работать.</li>
          </ul>
          <div>Что же делать:</div>
          <ul>
            <li>Попробуйте воспользоваться сервисом позднее.</li>
            <li>Попробуйте вернуться на <a href='/'>главную страницу</a> и начать сначала.</li>
            <li>Позвоните нам и мы постараемся решить проблему максимально быстро.</li>
          </ul>
          <div>Служба сервиса: +7 (3952) 465-391</div>
        </div>
      </div>
    </div>
  )
}

export default ErrorWindow;
export const write = (key: string, value: any, prefix?: string) => {
  localStorage.setItem(prefix ? `${prefix}__${key}` : key, JSON.stringify(value));
};

export const read = (key: string, prefix?: string): string | number | null => {
  const val = localStorage.getItem(prefix ? `${prefix}__${key}` : key);

  if (val) {
    return JSON.parse(val);
  }

  return null;
};

export const remove = (key: string, prefix?: string) => {
  const val = localStorage.getItem(prefix ? `${prefix}__${key}` : key);

  if (val) {
    localStorage.removeItem(prefix ? `${prefix}__${key}` : key);
  }
};

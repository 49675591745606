import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';

import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    this.setState({
      ...this.state,
      hasError: true,
    });
  }

  render() {
    const {
      state: { hasError },
      props: { children },
    } = this;

    if (hasError) {
      return (
        <div className={styles['ErrorBoundary']}>
          <Jumbotron className={styles['ErrorBoundary__content']}>
            <h1 className={styles['ErrorBoundary__title']}>
              Сайт временно недоступен.
              <br />
              Ведутся технические работы.
            </h1>
            <br />
            <h2 className={styles['ErrorBoundary__title']}>Благодарим за ожидание!</h2>
          </Jumbotron>
        </div>
      );
    } else {
      return children;
    }
  }
}

export default ErrorBoundary;

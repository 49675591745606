import React from 'react';
import { toast } from 'react-toastify';
import { injectIntl, IntlShape } from 'react-intl';
import cn from 'classnames';

import { HttpResponse } from '@api';
import { useAppStore } from '@store';
import UserStore from '@stores/UserStore';
import OrderDataStore from '@stores/OrderDataStore';

import { ButtonCustom } from '@components/common';
import styles from './CodeSubmitForm.module.scss';

interface Props {
  intl: IntlShape;
  locale: string | undefined;
  storedEmail: UserStore['email'];
  storedSessionId: string;
  touched: UserStore['touched'];
  errors: UserStore['errors'];
  status: UserStore['authStatus'];
  storedCode: UserStore['code'];
  codeStatus: UserStore['codeStatus'];
  timer: UserStore['timer'];
  timerId: UserStore['timerId'];
  setErrors: UserStore['setErrors'];
  setEmailValue: UserStore['setEmail'];
  getConfirmationCodeRequest: OrderDataStore['getConfirmationCode'] | OrderDataStore['requestConfirmCode'];
  setCode: UserStore['setCode'];
  setTouched: UserStore['setTouched'];
  setAuthStatus: UserStore['setAuthStatus'];
  setTimer: UserStore['setTimer'];
  checkConfirmationCodeRequest: OrderDataStore['checkConfirmationCode'] | OrderDataStore['requestAuth'];
  setCodeStatus: UserStore['setCodeStatus'];
  setSessionId: UserStore['setSessionId'];
  successFunction: () => void;
  isDisabled: boolean;
}

const CodeSubmitForm: React.FC<Props> = props => {
  const {
    intl,
    storedEmail,
    touched,
    codeStatus,
    errors,
    status,
    storedCode,
    timer,
    setErrors,
    setEmailValue,
    setTouched,
    getConfirmationCodeRequest,
    locale,
    setAuthStatus,
    setTimer,
    setCode,
    checkConfirmationCodeRequest,
    setCodeStatus,
    setSessionId,
    successFunction,
    storedSessionId,
    isDisabled,
  } = props;

  const t = intl.formatMessage;
  const { orderDataStore } = useAppStore();
  const emailValidation = (email: string) => {
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s?$/;

    if (regEx.test(email)) return null;
    if (email.trim() === '') return t({ id: 'common.emailRequired' });

    return t({ id: 'common.emailIncorrect' });
  };

  const handleEmailBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const error = emailValidation(value);

    if (touched?.email) {
      setErrors({ email: error });
    } else {
      setErrors({ email: null });
    }
  };

  const handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setEmailValue(value);
    setTouched({ email: true });
  };

  const handleEmailSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const validationError = storedEmail && emailValidation(storedEmail);

    if (validationError) {
      setErrors({ email: validationError });
      toast.error(validationError);
      // eslint-disable-next-line
      return console.error('Set new email error');
    }

    if (!validationError) {
      return (
        storedEmail &&
        getConfirmationCodeRequest(locale, storedEmail, storedSessionId)
          .then(({ data, error }: { data: any; error: string }) => {
            if (error) {
              throw new Error(error);
            } else {
              setErrors({ email: null });
              setAuthStatus({
                status: 'success',
                message: data.replyMessage || null,
              });
              if (!timer) {
                setTimer(30000);
              }
            }
          })
          // eslint-disable-next-line
          .catch((e: Error) => console.error(e))
      );
    }
    // eslint-disable-next-line
    console.error('handleEmailSubmit: Failed');
  };

  const handleCodeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
  };

  const handleCodeSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (storedEmail && storedCode) {
      checkConfirmationCodeRequest(locale, storedEmail, storedCode, storedSessionId)
        .then(({ data, error }: HttpResponse) => {
          if (error) {
            setErrors({ code: error });
            throw new Error(error);
          } else {
            setCodeStatus({
              status: data.success,
              message: data.replyMessage,
            });

            if (data.success) {
              orderDataStore.setConfirmation(data);

              if (data.sessionId) {
                setSessionId(data.sessionId);
              }

              return successFunction();
            } else {
              toast.error(data.replyMessage);
              setErrors({ code: data.replyMessage });
              throw new Error(data.replyMessage);
            }
          }
        })
        // eslint-disable-next-line
        .catch((error: any) => console.error(error));
    }
  };

  return (
    <>
      {/* EMAIL SUBMIT FORM */}
      <form className={styles['CodeSubmitForm']} onSubmit={handleEmailSubmit} autoComplete="off">
        <div className={styles['CodeSubmitForm__wrapper']}>
          <label htmlFor="email" className={styles['CodeSubmitForm__input-label']}>
            <input
              className={cn(
                styles['CodeSubmitForm__input-text'],
                errors?.email ? styles['CodeSubmitForm__error-input'] : null,
              )}
              type="text"
              placeholder="yourname@email.ru"
              disabled={status?.status === 'success' || isDisabled}
              value={storedEmail}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              name="email"
              required
            />
          </label>

          {/* Отправить код */}
          <div className={styles['CodeSubmitForm__button-wrapper']}>
            <ButtonCustom
              type="primary-outlined"
              buttonType="submit"
              disabled={status?.status === 'success' || isDisabled}
            >
              {t({ id: 'button.sendCodeBtn' })}
            </ButtonCustom>
          </div>
        </div>

        {touched?.email && errors?.email ? ( // show errors
          <div className={styles['CodeSubmitForm__error-text']}>{errors?.email}</div>
        ) : null}

        {status?.status === 'success' ? ( //show descriptor or timer
          <>
            <div className={styles['CodeSubmitForm__descriptor']}>{status?.message}</div>
            <div className={styles['CodeSubmitForm__timer']}>{`${t({ id: 'common.timer' })} ${timer}`}</div>
          </>
        ) : (
          <div className={styles['CodeSubmitForm__descriptor']}>{status?.message}</div>
        )}
      </form>

      {/*CODE SUBMIT FORM*/}
      {status?.status === 'success' || status?.status === 'waiting' ? ( // show code input when status is success
        <form className={styles['CodeSubmitForm']} onSubmit={handleCodeSubmit} autoComplete="off">
          <div className={styles['CodeSubmitForm__subtitle']}>{t({ id: 'common.codeLabel' })}</div>

          <div className={styles['CodeSubmitForm__wrapper']}>
            <label htmlFor="code" className={styles['CodeSubmitForm__input-label']}>
              <input
                className={cn(
                  styles['CodeSubmitForm__input-code'],
                  errors?.code ? styles['CodeSubmitForm__error-input'] : null,
                )}
                type="number"
                placeholder="12345"
                onChange={handleCodeChange}
                value={storedCode}
                maxLength={5}
                minLength={5}
                name="code"
                disabled={isDisabled}
                required
              />
            </label>

            {/* Подтвердить email */}
            <div className={styles['CodeSubmitForm__button-wrapper']}>
              <ButtonCustom
                buttonType="submit"
                disabled={isDisabled}
              >
                {t({ id: 'common.loginBtn' })}
              </ButtonCustom>
            </div>
          </div>

          {errors?.code ? (
            <div className={styles['CodeSubmitForm__code-info-error']}>{errors?.code}</div>
          ) : (
            <div className={styles['CodeSubmitForm__code-info']}>{codeStatus?.message}</div>
          )}
        </form>
      ) : null}
    </>
  );
};

export default injectIntl(CodeSubmitForm);

import { action, autorun, observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment';
import lodashGet from 'lodash.get';

import { TRANSPORT, PERMISSIONS } from '@constants';
import { IRoute, IVillage, ITransport, ICamp } from '@typing/Trip';
import { AppSettingsService } from '@services';

const { getAllAppSettings } = AppSettingsService;

const { PERMISSION_TYPE_TRANZIT } = PERMISSIONS;
const { TRANSPORT_PRIVATE_ID, TRANSPORT_PUBLIC_ID } = TRANSPORT;

const initialTripPoint = {
  id: 'tripPoint' + new Date().getTime(),
  pointIndex: null,
  routeIndex: null,
  beginDate: null,
  endDate: null,
  name: 'initial Name',
  wayOfLiving: null,
  wayToTravel: null,
  transportRequirement: false,
  territoryName: null,
};

const initialTranzitPoint = {
  id: 'tranzitPoint' + new Date().getTime(),
  village: '',
  reasonForVisit: '',
  vehicle: null,
  vehicleModel: '',
  vehicleNumber: '',
  pointIndex: null,
  routeIndex: null,
  beginDate: null,
  endDate: null,
  name: 'initial Tranzit Name',
};

const initialTransport = {
  model: '',
  number: '',
  type: '',
  typeId: '',
  routeId: '',
  ruRegion: undefined,
};

const initialCamp = {
  cId: 'camp' + new Date().getTime(),
  cordon: undefined,
  beginDate: null,
  endDate: null,
  path: [null, null, null],
  ParentTripRouteId: '',
  countTents: 1,
};

export default class TripStore {
  @persist('object') @observable tripStoreExpireDate: Date | null = null;

  @persist('list') @observable livingSettings: any[] = []; // для удобства перенос из сторы справочника

  @persist('object') @observable name: {} = {
    // заглушка ? артефакт
    id: '42dabc2c-ea98-d8d7-a345-325b7cd3b646',
    name: 'Листвянка – Б. Голоустное',
  };

  @persist('object') @observable beginDate: Date | null = null;

  @persist('object') @observable endDate: Date | null = null;

  @persist('list') @observable routes: IRoute[] = [];

  @persist('list') @observable villages: IVillage[] = [];

  @persist @observable isTransportRequired: boolean = false; // флаг, добавляемый если в поле выбрано перемещение на транспорте (пока хардкод, нет признака транспорта в базе)

  @persist('list') @observable transport: ITransport[] = []; // добавленный транспорт

  @persist @observable transportObjectId: number = 200;

  @persist @observable isTransportAvailable: boolean = false; //

  @persist @observable transportModelsSelectShow: boolean = false; // показывать ли селект с марками авто (для авто и авто 4х4)

  @persist('list') @observable camps?: ICamp[] = [];

  @persist @observable comment: string = ''; // поле комментарий

  @persist @observable currentActiveTerritory: string = ''; //в фокусе

  @persist @observable currentActiveRoute: string = ''; //в фокусе

  @persist @observable visitCount: number = 0;

  @persist @observable isCordon: boolean = false;

  @observable selectOptions: { label: string; value: any }[] = [];

  @observable visitVehicles: { id: string; name: string }[] = [];

  @action
  increaseCampQuantity = (campIndex: number): void => {
    if (this.camps) this.camps[campIndex].countTents++;
  };

  @action
  decreaseCampQuantity = (campIndex: number): void => {
    if (this.camps) this.camps[campIndex].countTents--;
  };

  @action
  setSelectOptions = (options: { label: string; value: any }[]) => {
    this.selectOptions = options;
  };

  @action
  loadVisitVehicles = async (lang: string) => {
    return getAllAppSettings(lang).then(({ error, data }) => {
      if (!error) {
        const transportsId = [TRANSPORT_PRIVATE_ID, TRANSPORT_PUBLIC_ID];
        const transportWays = data.filter((item: any) => {
          const { id } = item;
          return id && transportsId.includes(id);
        });
        this.visitVehicles = transportWays;
      }
    });
  };

  @action
  setTripStoreExpireDate = (date: Date | null) => {
    this.tripStoreExpireDate = date;
  };

  compareExpireDate = autorun(() => {
    // срок жизни данных не больше дня
    if (this.tripStoreExpireDate) {
      if (moment().isAfter(this.tripStoreExpireDate, 'day')) {
        this.setDefault();
        const today = new Date();
        this.setTripStoreExpireDate(today);
      }
    }
  });

  @action
  setLivingSettings = (livingSett: any[]): void => {
    // установка справочных значений размещений
    runInAction(() => {
      this.livingSettings = livingSett;
    });
  };

  @action
  setFirstVisit = (): void => {
    runInAction(() => {
      this.visitCount = this.visitCount + 1;
    });
  };

  @action
  handleValid = (cb: () => void): void => {
    runInAction(() => {
      cb();
    });
  };

  @action
  setTransportModelsSelectShow = (val: boolean) => {
    this.transportModelsSelectShow = val;
  };

  @action
  checkCampsSelected = () => {
    // проверка массива стоянок на принадлежность выбранным маршрутам
    let allTripRoutesIds = {};

    this.routes.forEach((tripRoute: any) => {
      if (tripRoute.id) {
        const isCampLivingRoute =
          this.livingSettings[tripRoute.wayOfLiving]?.id === 'bf58b77c-33fd-bcba-1590-09a8db7bc67e' ||
          this.livingSettings[tripRoute.wayOfLiving]?.id === '8953cee3-c8a4-50dc-226e-6dd7d53ebbb0';

        if (isCampLivingRoute) {
          allTripRoutesIds = {
            ...allTripRoutesIds,
            [tripRoute.id]: tripRoute.routeIndex,
          };
        }
      }
    });

    let cleanedPickedCamps = this.camps
      ? (this.camps.filter(
          (camp: any) =>
            allTripRoutesIds[camp.ParentTripRouteId] >= 0 && allTripRoutesIds[camp.ParentTripRouteId] === camp.path[1],
        ) as any[])
      : [];

    runInAction('cleanedPickedCamps', () => {
      this.camps = cleanedPickedCamps || [];
    });
  };

  @action
  allTripRoutesIndexes = (sett: any): any[] => {
    // возвращает индекс-пути добавленных маршрутов, где выбрано размещение "Палатка", н-р, [0,0] - в справочных данных у текущего парка первая территория, первый маршрут
    let all = [];

    for (let i = 0; i < this.routes.length; i++) {
      let rou = this.routes[i];

      if (
        sett.wayOfLiving[rou.wayOfLiving!]?.id === 'bf58b77c-33fd-bcba-1590-09a8db7bc67e' ||
        sett.wayOfLiving[rou.wayOfLiving!]?.id === '8953cee3-c8a4-50dc-226e-6dd7d53ebbb0' // id 'Палатка'
      ) {
        all.push([this.routes[i].pointIndex, this.routes[i].routeIndex]);
      }
    }

    return all;
  };

  // Points маршруты
  @action
  setPoint = (
    //добавление/изменение территории или маршрута
    placeIndex: number, // номер по порядку
    pointIndex: number, // индекс территории в справочных
    routeIndex: number, // индекс маршрута в справочных
  ) => {
    runInAction('setPointRouteIndex', () => {
      const curRoute = this.routes[placeIndex];
      if (curRoute) {
        curRoute.pointIndex = pointIndex;
        curRoute.routeIndex = routeIndex;
      }

      const curVillage = this.villages[placeIndex];
      if (curVillage) {
        curVillage.pointIndex = pointIndex;
        curVillage.routeIndex = routeIndex;
      }
    });
  };

  @action
  setActiveTerra = (terraId: string) => {
    // фокус на территории
    this.currentActiveTerritory = terraId;
  };

  @action
  setActiveRoute = (rouId: string) => {
    // фокус на маршруте
    this.currentActiveRoute = rouId;
  };

  @action
  addPoint = (target?: 'map', obj?: any, objData?: any) => {
    //добавление Территория/Маршрут

    if (target === 'map' && obj) {
      //вызов с карты
      switch (obj) {
        case 'T': {
          runInAction(() => {
            // если территория еще не выбрана меняем текущую, иначе создаем новую
            if (this.routes[0] && this.routes[0].pointIndex === null) {
              this.routes = [{ ...initialTripPoint, initialIndex: objData }];
            } else {
              this.routes = [
                ...this.routes,
                {
                  ...initialTripPoint,
                  id: 'tripPoint' + new Date().getTime(),
                  initialIndex: objData,
                },
              ];
            }
          });
          break;
        }
        case 'R': {
          runInAction('createRouteFromMap', () => {
            if (this.routes[this.routes.length - 1]?.routeIndex! !== null) {
              this.routes = [
                ...this.routes,
                {
                  ...initialTripPoint,
                  id: 'tripPoint' + new Date().getTime(),
                },
              ];
            } else if (this.routes.length > 0 && this.routes[this.routes.length - 1].routeIndex === null) {
              this.routes = [
                ...this.routes.slice(0, this.routes.length - 1),
                {
                  ...initialTripPoint,
                  id: 'tripPoint' + new Date().getTime(),
                },
              ];
            } else {
              this.routes = [
                {
                  ...initialTripPoint,
                  id: 'tripPoint' + new Date().getTime(),
                },
              ];
            }
          });

          if (this.routes) {
            this.setPoint(this.routes.length - 1, objData[0], objData[1]);
          } else {
            // eslint-disable-next-line
            console.error('FIRST MUST BE TERRITORY ADDED');
          }

          break;
        }
      }
    } else {
      // если не с карты добавляем пустой
      runInAction(() => {
        this.routes = [...this.routes, { ...initialTripPoint, id: 'tripPoint' + new Date().getTime() }];
      });
    }
  };

  @action
  addTranzitPoint = () => {
    runInAction(() => {
      this.villages = [...this.villages, { ...initialTranzitPoint, id: 'tranzitPoint' + new Date().getTime() }];
    });
  };

  @action
  dellPoint = (placeIndex: number) => {
    // удалить маршрут из добавленных
    let cleaned: any[] = [];

    for (let i = 0; i < this.routes.length; i++) {
      if (i !== placeIndex) {
        cleaned.push(this.routes[i]);
      }
    }

    runInAction(() => {
      this.routes = cleaned;
    });
  };

  @action
  dellTranzitPoint = (placeIndex: number) => {
    // удалить маршрут из добавленных
    let cleaned: any[] = [];

    for (let i = 0; i < this.villages.length; i++) {
      if (i !== placeIndex) {
        cleaned.push(this.villages[i]);
      }
    }

    runInAction(() => {
      this.villages = cleaned;
    });
  };

  @action
  cleanRoutes = () => {
    // сбросить все маршруты - не используется вне дев-режима
    runInAction(() => {
      this.routes = [{ ...initialTripPoint, id: 'tripPoint' + new Date().getTime() }];
    });
  };

  @action
  setDate = (
    //установка дат
    placeIndex: number,
    date: Date | null,
    markWhere: 'trip' | 'place' | 'camp' | 'tranzit',
    markStartEnd: 'endDate' | 'beginDate',
  ) => {
    switch (markWhere) {
      case 'trip': {
        this[markStartEnd] = date;
        break;
      }
      case 'place': {
        this.routes[placeIndex][markStartEnd] = date;
        break;
      }
      case PERMISSION_TYPE_TRANZIT: {
        this.villages[placeIndex][markStartEnd] = date;
        break;
      }
      case 'camp': {
        if (this.camps) {
          this.camps[placeIndex][markStartEnd] = date;
        }
        break;
      }
      default: {
      }
    }
  };

  @action
  setTripTerritoryName = (placeIndex: number, territoryName: string) => {
    this.routes[placeIndex].territoryName = territoryName;
  };

  @action
  setTripMode = (placeIndex: number, wayToTravel: number) => {
    // установка типа перемещения
    this.routes[placeIndex].wayToTravel = wayToTravel;
  };

  @action
  setVillage = (placeIndex: number, village: any) => {
    // установка данных о посещаемом нас. пункте
    const villageCurrent = this.villages[placeIndex];
    const { name, id } = village;

    villageCurrent.id = id;
    villageCurrent.name = name;
    villageCurrent.village = name;
  };

  @action
  setReasonForVisit = (placeIndex: number, str: string) => {
    // установка цели посещения
    this.villages[placeIndex].reasonForVisit = str;
  };

  @action
  setVehicle = (placeIndex: number, str: string) => {
    // установка транспортного ср-ва
    this.villages[placeIndex].vehicle = str;
  };

  @action
  setVehicleModel = (placeIndex: number, str: string) => {
    // установка модели транспортного ср-ва
    this.villages[placeIndex].vehicleModel = str;
  };

  @action
  setVehicleNumber = (placeIndex: number, str: string) => {
    // установка номера транспортного ср-ва
    this.villages[placeIndex].vehicleNumber = str;
  };

  @action
  setHost = (placeIndex: number, wayOfLiving: number) => {
    // установка типа проживания
    runInAction(() => {
      this.routes[placeIndex]['wayOfLiving'] = wayOfLiving;
      //this.routes = [...this.routes]
    });
  };

  @action
  setIsTent = (placeIndex: number, isTent: boolean) => {
    runInAction(() => {
      this.routes[placeIndex]['isTent'] = isTent;
    });
  };

  // transport Блок экшенов транспорта
  @action
  setTransportRequirement = (requirement: boolean, routeId: string) => {
    // установка требования ввести транспорт
    this.isTransportRequired = requirement;

    const transportByRouteLength =
      this.transport.length && this.transport.filter((t: any) => t.ParentTripRouteId === routeId);

    if (transportByRouteLength < 1) {
      this.addTripTransport(routeId);
    }
  };

  @action
  increaseTransportObjectId = () => {
    this.transportObjectId = this.transportObjectId + 1;
  };

  @action
  addTripTransport = (routeId: string) => {
    // добавление нового транспортного средства
    this.transport = [
      ...this.transport,
      {
        ...initialTransport,
        id: this.transportObjectId,
        ParentTripRouteId: routeId,
      },
    ];

    this.increaseTransportObjectId();
  };

  @action
  dellTripTransport = (trIndex: number) => {
    // удаление транспортного средства
    let cleaned: any[] = [];

    for (let i = 0; i < this.transport.length; i++) {
      if (i !== trIndex) {
        cleaned.push(this.transport[i]);
      }
    }

    this.transport = cleaned;
  };

  @action
  dellTransportByRoute = (parentRouteId: string) => {
    this.transport = this.transport.filter((t: any) => t.ParentTripRouteId !== parentRouteId);
  };

  @action
  setTripTransport = (
    // установка значений для ТС
    trIndex: number = 0,
    option: 'model' | 'number' | 'type' | 'typeId' | 'routeId',
    value: string,
  ) => {
    this.transport[trIndex][option] = value;
  };

  @action
  cleanTripTransport = () => {
    // уделение всех транспортных средств
    this.transport = [];
  };

  // camps Блок экшенов мест стоянок
  @action
  addCamps = (ParentTripRouteId: string | null, target?: 'map' | 'form', campData?: any) => {
    const parentTripRouteId = lodashGet(this.routes, [this.routes.length - 1, 'id'], undefined) as string; // id последней в списке, добавляется если неизвестна принадлежность

    // добавление элемента стоянки (из формы создает пустую,
    // с карты созадет пустую и записывает выбранную)
    runInAction(() => {
      this.camps = [
        // @ts-ignore
        ...this.camps,
        {
          ...initialCamp,
          cordon: false,
          cId: 'camp' + new Date().getTime(),
          ParentTripRouteId: ParentTripRouteId || parentTripRouteId,
        },
      ];

      if (
        this.routes &&
        this.routes.length &&
        target === 'map' &&
        this.camps &&
        this.camps.length &&
        campData &&
        campData['path'] &&
        parentTripRouteId
      ) {
        this.setCamps(
          this.camps ? this.camps.length - 1 : 0,
          campData['path'][0],
          campData['path'][1],
          campData['path'][2],
          parentTripRouteId,
        );
      } else if (this.routes && this.routes.length && target === 'form' && campData && ParentTripRouteId) {
        this.setCamps(this.camps ? this.camps.length - 1 : 0, campData[0], campData[1], 0, ParentTripRouteId);
      }
    });
  };

  @action
  dellCamps = (campIndex: number) => {
    // удаление стоянки из добавленных
    let cleaned: any[] = [];

    if (this.camps) {
      for (let i = 0; i < this.camps.length; i++) {
        if (i !== campIndex) {
          cleaned.push(this.camps[i]);
        }
      }
    }

    runInAction(() => {
      this.camps = cleaned;
    });
  };

  @action
  setCamps = (
    // установка стоянки
    campIndex: number, // порядок в добавленных
    territoryIndex: number, // принадлежность к территории из справочных
    routeIndex: number, // принадлежность к маршруту из справочных
    targetIndex: number, // индекс  из справочных
    ParentTripRouteId: string,
  ) => {
    runInAction(() => {
      if (this.camps) {
        // если только одна и пустая, то пересоздать (иначе мобикс не даст сигнал обсервить, в ином случае это не нужно
        if (this.camps.length === 1 && this.camps[0]['path'][0] === undefined) {
          this.camps = [
            {
              ...initialCamp,
              path: [territoryIndex, routeIndex, targetIndex],
              ParentTripRouteId: ParentTripRouteId,
            },
          ];
        } else {
          this.camps[campIndex]['path'] = [territoryIndex, routeIndex, targetIndex];
        }
      } else {
        // eslint-disable-next-line
        console.error('CAMPs undefined. Creation need', this.camps);
      }
    });
  };

  @action
  cleanCamps = () => {
    // сброс стоянок
    runInAction(() => {
      this.camps = [];
    });
  };

  @action
  cleanCampsByRouteId = (routeId: string) => {
    runInAction(() => {
      this.camps = this.camps && this.camps.filter(c => c && c.ParentTripRouteId !== routeId);
    });
  };

  @action
  setComment = (comment: string) => {
    // установка значения комментария
    runInAction(() => {
      this.comment = comment;
    });
  };

  @action
  setCordon = (isCordon: boolean) => {
    this.isCordon = isCordon;
  };

  @action
  setDefault = () => {
    runInAction(() => {
      this.name = {
        id: '42dabc2c-ea98-d8d7-a345-325b7cd3b646',
        name: 'Листвянка – Б. Голоустное',
      };
      this.beginDate = null;
      this.endDate = null;
      this.routes = [
        {
          ...initialTripPoint,
          id: 'tripPoint' + new Date().getTime(),
        },
      ];
      this.villages = [
        {
          ...initialTranzitPoint,
          id: 'tranzitPoint' + new Date().getTime(),
        },
      ];
      this.isTransportRequired = false;
      this.transport = [];
      this.isTransportAvailable = false;
      this.camps = [];
      this.comment = '';
      this.currentActiveTerritory = '';
      this.currentActiveRoute = '';
      this.visitCount = 0;
      this.visitVehicles = [];
    });
  };
}

const rest = {
  current: 'api/',
  old: 'v2/services/ausweiss_RestManagerService/',
};

export default {
  LANGUAGES: {
    ru: 'ru',
    en: 'en',
  },
  REST_CURRENT: rest.current,
  REST_OLD: 'v2/services/ausweiss_RestManagerService/',
};

export const isLogging = false; // вкл-выкл фронтовых логов
export const isMapShown = true;
export const isDevChecker = { is: false, label: '' };
export const CUBA_APP_URL = process.env.REACT_APP_CUBA_URL || '/components/rest/';
export const PROCESS_ENV = process.env.NODE_ENV;

const SERVER_HOST_PRODUCTION = CUBA_APP_URL;

// connect to prod urls inside dev-mode, need to active: true
const devApiConfig = {
  // default DEV API - pribaykalie test
  pribaykalieTest: {
    url: 'https://test.baikalpass.ru/ausweiss/rest/',
    // url: 'https://test.baikalpass.rus',
  },

  // connect to pribaykalie PROD
  pribaykalieProd: {
    active: false, // ------------> switch to FALSE after testing!
    url: 'https://baikalpass.ru/ausweiss/rest/',
  },

  // connect to podlemorie PROD
  podlemorieProd: {
    active: false, // ------------> switch to FALSE after testing!
    url: 'https://podlemorye.groupstp.ru/ausweiss/rest/',
  },
};

const returnDevUrl = () => {
  const { pribaykalieProd, podlemorieProd, pribaykalieTest } = devApiConfig;

  if (pribaykalieProd.active) {
    return pribaykalieProd.url;
  }

  if (podlemorieProd.active) {
    return podlemorieProd.url;
  }

  return pribaykalieTest.url;
};

export const SERVER_HOST = PROCESS_ENV === 'production' ? SERVER_HOST_PRODUCTION : returnDevUrl();

if (isLogging) {
  // eslint-disable-next-line
  console.log(':: HOST NOW ::', SERVER_HOST, 'process.env', process.env);
}

export const version = '0.2.10';
